import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AbstractComponent } from 'src/app/Injector/abstract/abstract.component';

@Component({
  selector: 'app-linkedin',
  templateUrl: './linkedin.component.html',
  styleUrls: ['./linkedin.component.scss']
})
export class LinkedinComponent extends AbstractComponent implements OnInit {

  constructor(
    injector: Injector,
    public activatedRoute: ActivatedRoute,
  ) {
    super(injector);

    activatedRoute.queryParams.subscribe((prms) => {
      const invite = localStorage.getItem("invite");
      let data: any = prms;
      if (invite) {
        data = { ...prms, ...{ invite: invite } };
      };
      this._api.loginLinkedin(data).subscribe((res: any) => {
        if (res.success) {
          this._cookies.setCookie('t', btoa(JSON.stringify(res.data.token)), 1);
          if (!this._user.session) {
            this._api.me().subscribe(
              (res: any) => {
                this._user.session = res.data;
                this._socket.initializeSocketConnection(res.data.user);
                this._app.getInstalledApp();
              },
              (err: any) => { }
            );
          }
          this._com.redirect();
          if (res.data.recently_created) {
            this._notify.success("Thank you. Check your e-mail inbox.");
          }
        }
      });
    })
  }

  ngOnInit(): void {
  }

}


import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { SnackService } from '../snack/snack.service';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  public isLoading: boolean;
  public company_profile: any = [];
  public countries: any = [];
  constructor(private _notify: SnackService, private _api: ApiService) {}

  public getCountries() {
    this._api.getCountries().subscribe(
      (res: any) => {
        if (res.success) {
          this.isLoading = false;
          this.countries = res.data;
        } else {
          this.isLoading = false;
          this._notify.error(res.message);
        }
      },
      (err: any) => {
        if (err.error) {
          this.isLoading = false;
          this._notify.error(err.error.message);
        }
      }
    );
  }
}

import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { SnackService } from '../snack/snack.service';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  public installedApp: any;
  constructor(private notify: SnackService, private _api: ApiService) {
    this.getInstalledApp();
  }

  public getInstalledApp() {
    this._api.getInstalledApp().subscribe(
      (res: any) => {
        if (res.success) {
          this.installedApp = res.data;
          localStorage.setItem('app', btoa(JSON.stringify(res.data)));
        } else {
          this.notify.error(res.message);
        }
      },
      (err: any) => {
        if (err.error) {
          this.notify.error(err.error.message);
        }
      }
    );
  }

}

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../api/api.service';
import { CookiesService } from '../cookies/cookies.service';
import { FeedService } from '../feed/feed.service';
import { SocketService } from '../socket/socket.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  public session: any = null;
  constructor(
    private api: ApiService,
    private _cookie: CookiesService,
    private _feed: FeedService,
    private _router: Router,
    private _socket: SocketService
  ) {
    if (!this.session) {
      this.api.me().subscribe(
        (res: any) => {
          if (res.success) {
            this.session = res.data;
            this._socket.initializeSocketConnection(res.data.user);
          } else {
          }
        },
        (err: any) => {}
      );
    }
  }

  public isLoggedin() {
    if (this.session) {
      return true;
    } else {
      return false;
    }
  }

  public logout() {
    localStorage.clear();
    this._cookie.eraseCookie('t');
    this.session = null;
    this.api.token = null;
    this._feed.bookmarks = [];
    this._router.navigate(['/home']);
    setTimeout(() => {
      window.location.reload();
    }, 0);
  }
}

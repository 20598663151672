import { Component, ElementRef, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AbstractComponent } from 'src/app/Injector/abstract/abstract.component';
import findIndex from 'lodash/findIndex';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
})
export class ChatComponent extends AbstractComponent implements OnInit {
  @ViewChild('chatInput') chatInput: ElementRef;
  @ViewChild('chatContainer') chatContainer: ElementRef;

  public panelOpenState: boolean = true;
  public section: string = '';
  public data: any;
  public message: any = '';
  public messages: any = [];
  public inProgress: any = [];
  @Input() public user: any;
  public viewerOpen = false;
  constructor(injector: Injector, private route: ActivatedRoute) {
    super(injector);

    this.inProgress = [
    ];
    this.route.data.subscribe((data) => {
      this.data = data;
    });
  }

  ngOnInit(): void {
    this._chat.loadChat();
  }

  sendMessage = (e = null, type = 'text') => {
    if (e) {
      e.preventDefault();
    }

    if (this.message && this.message.length > 0) {
      const data = {
        message: this.message,
        to_user: this.user.id,
        section: this.section,
        type: type,
        program_id: this._chat.programId,
      };
      this._chat.sendMessage(data);
    }

    this.message = '';
  };

  uploadFile = (event, type) => {
    const d = new Date();
    const id: any = d.getTime();
    let message: any = {};
    if (event.target.files && event.target.files[0].size > 10000000) {
      this._notify.error('Please select file less than 10mb.');
    }
    if (event.target.files && event.target.files[0].size <= 10000000) {
      const file = event.target.files[0];

      const reader = new FileReader();
      reader.onload = (e) => {
        message.message = reader.result;
      };

      reader.readAsDataURL(file);

      message.to_user = this.user.id;
      message.id = id;
      message.section = this.section;
      message.type = type;
      message.program_id = this._chat.programId;

      this.inProgress.push(message);


      const data = { file: file, type: "chat" };

      this._api.uploadMedia(data).subscribe(
        (res: any) => {
          let i = findIndex(this.inProgress, { id });
          if (i >= 0) {
            this.inProgress.splice(i, 1);

            const data = {
              message: res.data.path,
              to_user: this.user.id,
              section: this.section,
              type: type,
              program_id: this._chat.programId,
            };
            this._chat.sendMessage(data);
          }
        },
        (err: any) => {
          let i = findIndex(this.inProgress, { id });
          if (i >= 0) {
            this.inProgress.splice(i, 1);
          }
        }
      );
    }

  };

  textAreaAdjust = () => {
    this.chatInput.nativeElement.style.height = '50px';
    var height = `${this.chatInput.nativeElement.scrollHeight}px`;
    var maxHeight = '129px';
    var minHeight = '431px';
    var containerHeight = `${560 - this.chatInput.nativeElement.scrollHeight}px`;
    this.chatInput.nativeElement.style.height = height;
    this.chatInput.nativeElement.style.maxHeight = maxHeight;
    this.chatContainer.nativeElement.style.height = containerHeight;
    this.chatContainer.nativeElement.style.minHeight = minHeight;
  }
}

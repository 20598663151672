import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProgramcardComponent } from './programcard.component';
import { MyProgramCardComponent } from './my-program-card/my-program-card.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { ChartModule } from '../chart/chart.module';

@NgModule({
  declarations: [ProgramcardComponent, MyProgramCardComponent],
  exports: [ProgramcardComponent, MyProgramCardComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    MatMenuModule,
    MatIconModule,
    MatTooltipModule,
    ClipboardModule,
    ChartModule,
    ReactiveFormsModule,
  ],
})
export class ProgramcardModule {}

import { Component, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api/api.service';
import { AppMarketService } from 'src/app/services/app-market/app-market.service';
import { AppService } from 'src/app/services/app/app.service';
import { ChapterService } from 'src/app/services/chapter/chapter.service';
import { ChatService } from 'src/app/services/chat/chat.service';
import { CommonService } from 'src/app/services/common/common.service';
import { CompanyService } from 'src/app/services/company/company.service';
import { CookiesService } from 'src/app/services/cookies/cookies.service';
import { FeedService } from 'src/app/services/feed/feed.service';
import { LearningService } from 'src/app/services/learning/learning.service';
import { MentoringService } from 'src/app/services/mentoring/mentoring.service';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { ProgramService } from 'src/app/services/program/program.service';
import { SearchService } from 'src/app/services/search/search.service';
import { SeoService } from 'src/app/services/seo/seo.service';
import { ShareService } from 'src/app/services/share/share.service';
import { SnackService } from 'src/app/services/snack/snack.service';
import { SocketService } from 'src/app/services/socket/socket.service';
import { SsoService } from 'src/app/services/sso/sso.service';
import { StripeService } from 'src/app/services/stripe/stripe.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-abstract',
  templateUrl: './abstract.component.html',
  styleUrls: ['./abstract.component.scss'],
})
export class AbstractComponent {
  public _router: Router;

  public _sso: SsoService;
  public _api: ApiService;
  public _com: CommonService;
  public _notify: SnackService;
  public _program: ProgramService;
  public _profile: ProfileService;
  public _chapter: ChapterService;
  public _cookies: CookiesService;
  public _user: UserService;
  public _company: CompanyService;
  public _feed: FeedService;
  public _learning: LearningService;
  public _mentoring: MentoringService;
  public _socket: SocketService;
  public _stripe: StripeService;
  public _chat: ChatService;
  public _search: SearchService;
  public _share: ShareService;
  public _appMarket: AppMarketService;
  public _app: AppService;
  public _seo: SeoService;

  // tslint:enable: variable-name

  constructor(injector: Injector) {
    this._router = injector.get(Router);
    this._sso = injector.get(SsoService);
    this._api = injector.get(ApiService);
    this._com = injector.get(CommonService);
    this._notify = injector.get(SnackService);
    this._program = injector.get(ProgramService);
    this._profile = injector.get(ProfileService);
    this._chapter = injector.get(ChapterService);
    this._cookies = injector.get(CookiesService);
    this._user = injector.get(UserService);
    this._company = injector.get(CompanyService);
    this._feed = injector.get(FeedService);
    this._learning = injector.get(LearningService);
    this._mentoring = injector.get(MentoringService);
    this._socket = injector.get(SocketService);
    this._stripe = injector.get(StripeService);
    this._chat = injector.get(ChatService);
    this._search = injector.get(SearchService);
    this._share = injector.get(ShareService);
    this._appMarket = injector.get(AppMarketService);
    this._app = injector.get(AppService);
    this._seo = injector.get(SeoService);
  }
}

import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { SnackService } from '../snack/snack.service';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  public isLoading: boolean;
  public profile: any = [];
  public countries: any = [];
  // public covers: any = [];

  constructor(private _notify: SnackService, private _api: ApiService) {}

  public getProfile() {
    this._api.profile('').subscribe(
      (res: any) => {
        if (res.success) {
          this.isLoading = false;
          this.profile = res.data;
        } else {
          this.isLoading = false;
          this._notify.error(res.message);
        }
      },
      (err: any) => {
        if (err.error) {
          this.isLoading = false;
          this._notify.error(err.error.message);
        }
      }
    );
  }

  public getCountries() {
    this._api.getCountries().subscribe(
      (res: any) => {
        if (res.success) {
          this.isLoading = false;
          this.countries = res.data;
        } else {
          this.isLoading = false;
          this._notify.error(res.message);
        }
      },
      (err: any) => {
        if (err.error) {
          this.isLoading = false;
          this._notify.error(err.error.message);
        }
      }
    );
  }

  // public getDefaultCovers() {
  //   this._api.getDefaultCovers().subscribe(
  //     (res: any) => {
  //       if (res.success) {
  //         this.isLoading = false;
  //         this.covers = res.data;
  //       } else {
  //         this.isLoading = false;
  //         this._notify.error(res.message);
  //       }
  //     },
  //     (err: any) => {
  //       if (err.error) {
  //         this.isLoading = false;
  //         this._notify.error(err.error.message);
  //       }
  //     }
  //   );
  // }
}

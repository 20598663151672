<div
  class="d-flex float-left w-100 flex-direction-unset"
  *ngIf="section && section.message"
>
  <audio #audioElement [src]="section.message"></audio>

  <span (click)="paused ? play() : pause()" class="media-btn">
    <i
      *ngIf="paused"
      (click)="isEdit = true"
      class="icon-play twenty-four cursor-pointer"
    ></i>
    <i
      *ngIf="!paused"
      (click)="isEdit = true"
      class="icon-pause twenty-four cursor-pointer"
    ></i>
  </span>
  <mat-slider
    class="time-slider"
    min="0"
    max="100"
    step="0.5"
    value="{{ audioProgress }}"
    (input)="onAudioProgressChange($event)"
  ></mat-slider>
  <span
    class="remaining-time ml-1"
  >
    <span *ngIf="audio" class="remaining-time-label">{{ convertHMS(audio?.currentTime) }}</span>
    <i class="icon-audio-new twenty-four"></i>
  </span>
</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { CircularChartComponent } from './circular-chart/circular-chart.component';
import { ActivityChartComponent } from './activity-chart/activity-chart.component';

@NgModule({
  declarations: [CircularChartComponent, ActivityChartComponent],
  exports: [CircularChartComponent, ActivityChartComponent],
  imports: [
    CommonModule,
    RouterModule,
  ]
})
export class ChartModule { }

import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AbstractComponent } from 'src/app/Injector/abstract/abstract.component';
import { Subscription, timer } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-payment-processing',
  templateUrl: './payment-processing.component.html',
  styleUrls: ['./payment-processing.component.scss'],
})
export class PaymentProcessingComponent
  extends AbstractComponent
  implements OnInit
{
  public timerSubscription: Subscription;

  constructor(private activatedRoute: ActivatedRoute, injector: Injector) {
    super(injector);
    this.activatedRoute.params
      .subscribe((prams) => {
        if (prams && prams.uuid) {
          this.timerSubscription = timer(0, 10000)
            .pipe(
              map(() => {
                this.checkPaymentStatus(prams.uuid);
              })
            )
            .subscribe();
        }
      })
      .unsubscribe();
  }

  public ngOnInit(): void {}

  ngOnDestroy(): void {
    this.timerSubscription.unsubscribe();
  }

  public checkPaymentStatus(uuid) {
    const data = {
      payment_id: uuid,
    };
    this._api.paymentStatus(data).subscribe(
      (res: any) => {
        if (res.success) {
          if (res.data.status != 'in-progress') {
            this._notify.success(res.message);
            this.timerSubscription.unsubscribe();
            this._router.navigate(['/profile/wallet']);
          }
        } else {
          this._notify.error(res.message);
          this.timerSubscription.unsubscribe();
          this._router.navigate(['/profile/wallet']);
        }
      },
      (err: any) => {
        this._notify.error(err.error.message);
        this.timerSubscription.unsubscribe();
        this._router.navigate(['/profile/wallet']);
      }
    );
  }
}

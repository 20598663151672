import { isPlatformBrowser } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import moment from 'moment-timezone';
import { environment as env } from 'src/environments/environment';
import { CookiesService } from '../cookies/cookies.service';
import {Upload} from 'tus-js-client';
import { Subject } from 'rxjs';

declare global {
  interface Window { visitorId: any; visitorInfo: any }
}

export interface FileStatus {
  filename: string;
  progress: number;
  hash: string;
  uuid: string;
}

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  public headers: any;
  public authHeaders: any;
  public token: any = {};
  private uploadStatus = new Subject<FileStatus[]>();
  uploadProgress = this.uploadStatus.asObservable();

  fileStatusArr: FileStatus[] = [];
  constructor(private http: HttpClient, private _cookies: CookiesService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.headers = new HttpHeaders().set('dataType', 'jsonp');
  }

  public loginGoogle(user) {
    const headers = this.headers;

    return this.http.post(`${env.apiUrl}/api/sso/google`, user, {
      headers,
    });
  }

  public userRegister(data) {
    const headers = this.headers;

    return this.http.post(`${env.apiUrl}/api/register-user`, data, {
      headers,
    });
  }  

  public userLogin(data) {
    const headers = this.headers;

    return this.http.post(`${env.apiUrl}/api/login`, data, {
      headers,
    });
  }  

  public forgotPassword(data) {
    const headers = this.headers;

    return this.http.post(`${env.apiUrl}/api/forgot/password`, data, {
      headers,
    });
  }  

  public verifyToken(token) {
    const headers = this.headers;

    return this.http.get(`${env.apiUrl}/api/validate-token/${token}`, {
      headers,
    });
  }  

  public verifyAccount(data) {
    const headers = this.headers;

    return this.http.post(`${env.apiUrl}/api/user/verify`, data, {
      headers,
    });
  }  

  public updatePassword(data) {
    const headers = this.headers;

    return this.http.post(`${env.apiUrl}/api/update/password`, data, {
      headers,
    });
  }  

  public me() {
    const headers = this.setSecureHeader();
    return this.http.get(`${env.apiUrl}/api/me`, {
      headers,
    });
  }

  public profile(slug = null) {
    const headers = this.setSecureHeader();
    var url = `${env.apiUrl}/api/user`;
    if (slug && slug.length > 0) {
      url = url + `/${slug}`;
    }
    return this.http.get(url, {
      headers,
    });
  }

  public companyProfile(slug) {
    const headers = this.setSecureHeader();
    var url = `${env.apiUrl}/api/company`;
    if (slug && slug.length > 0) {
      url = url + `/${slug}`;
    }
    return this.http.get(url, {
      headers,
    });
  }

  public getTeam(company_slug) {
    const headers = this.setSecureHeader();
    return this.http.get(`${env.apiUrl}/api/company/team/?company_slug=${company_slug}`, {
      headers,
    });
  }

  public cancelInvite(data) {
    const headers = this.setSecureHeader();;

    return this.http.post(`${env.apiUrl}/api/company/team/invite/cancel`, data, {
      headers,
    });
  }

  public removeEmployee(data) {
    const headers = this.setSecureHeader();;

    return this.http.post(`${env.apiUrl}/api/company/employee/remove`, data, {
      headers,
    });
  }

  public getCountries() {
    const headers = this.setSecureHeader();
    return this.http.get(`${env.apiUrl}/api/countries`, {
      headers,
    });
  }

  public getDefaultCovers() {
    const headers = this.setSecureHeader();
    return this.http.get(`${env.apiUrl}/api/profile/default-covers`, {
      headers,
    });
  }

  public updateCover(data, section) {
    const formData: FormData = new FormData();
    if (data && data.cover_id) {
      formData.append('cover_id', data.cover_id);
    }
    if (data && data.id) {
      formData.append('id', data.id);
    }
    if(data && data.originalImage) {
      formData.append('cover_photo', data.originalImage);
    } else {
      formData.append('cover_photo', data.cover);
    }
    if (data && data.crop) {
      formData.append('x', data.crop.x);
      formData.append('y', data.crop.y);
      formData.append('height', data.crop.height);
      formData.append('width', data.crop.width);
    }

    const headers = this.setSecureHeader();

    return this.http.post(`${env.apiUrl}/api/` + section + '/cover', formData, {
      headers,
    });
  }

  public getCommunity(data = {}) {
    const headers = this.setSecureHeader();
    return this.http.post(`${env.apiUrl}/api/community/list`, data, {
      headers,
    });
  }

  public getUserCommunity(data = {}) {
    const headers = this.setSecureHeader();
    return this.http.post(`${env.apiUrl}/api/community/user`, data, {
      headers,
    });
  }

  public search(term) {
    const headers = this.setSecureHeader();
    return this.http.post(`${env.apiUrl}/api/program/popup`, term, {
      headers,
    });
  }

  public searchUser(term) {
    const headers = this.setSecureHeader();
    return this.http.post(`${env.apiUrl}/api/search`, term, {
      headers,
    });
  }

  public getRecentUsersAvatar() {
    const headers = this.headers;

    return this.http.get(`${env.apiUrl}/api/users/avatars`, {
      headers,
    });
  }

  public loginLinkedin(user) {
    const headers = this.headers;

    return this.http.post(`${env.apiUrl}/api/sso/linkedin`, user, {
      headers,
    });
  }

  public createProgram(data) {
    const formData: FormData = new FormData();
    if (data && data.id) {
      formData.append('id', data.id);
    }
    formData.append('name', data.name);
    formData.append('overview', data.overview);
    if(data && data.originalImage) {
      formData.append('cover', data.originalImage);
    } else {
      formData.append('cover', data.cover);
    }
    if (data && data.crop) {
      formData.append('x', data.crop.x);
      formData.append('y', data.crop.y);
      formData.append('height', data.crop.height);
      formData.append('width', data.crop.width);
    }
    formData.append('category', data.category);
    formData.append('skills', data.skills);
    formData.append('to_whom', data.to_whom);
    formData.append('is_chat', data.is_chat);
    formData.append('start_date', data.start_date);
    formData.append('end_date', data.end_date);
    formData.append('timezone', data.timezone);
    formData.append('content_price', data.content_price);
    formData.append('content_description', data.content_description);
    formData.append('is_private', data.is_private);
    formData.append('discount', data.discount);
    formData.append('password', data.password);
    formData.append('chat_price', data.chat_price ? data.chat_price : 0);
    formData.append(
      'chat_description',
      data.chat_description ? data.chat_description : ''
    );

    const headers = this.setSecureHeader();
    return this.http.post(`${env.apiUrl}/api/program/add`, formData, {
      headers,
    });
  }

  public verifyPassword(data) {
    const headers = this.setSecureHeader();

    return this.http.post(
      `${env.apiUrl}/api/app/password/verify`,
      data,
      {
        headers,
      }
    );
  }

  public createChapter(data) {
    const headers = this.setSecureHeader();

    return this.http.post(`${env.apiUrl}/api/chapter/add`, data, {
      headers,
    });
  }

  public createSkill(data) {
    const headers = this.setSecureHeader();

    return this.http.post(`${env.apiUrl}/api/skill/create`, data, {
      headers,
    });
  }

  public endorseSkill(data) {
    const headers = this.setSecureHeader();

    return this.http.post(`${env.apiUrl}/api/endorse`, data, {
      headers,
    });
  }

  public inviteAuthor(data) {
    const headers = this.setSecureHeader();

    return this.http.post(`${env.apiUrl}/api/invite/author`, data, {
      headers,
    });
  }

  public inviteMentor(data) {
    const headers = this.setSecureHeader();

    return this.http.post(`${env.apiUrl}/api/invite/mentor`, data, {
      headers,
    });
  }

  public inviteToCompany(data) {
    const headers = this.setSecureHeader();

    return this.http.post(`${env.apiUrl}/api/company/employee/invite`, data, {
      headers,
    });
  }

  public deleteChapter(id) {
    const headers = this.setSecureHeader();

    return this.http.delete(`${env.apiUrl}/api/chapter/${id}`, {
      headers,
    });
  }

  public deleteProgram(id) {
    const headers = this.setSecureHeader();

    return this.http.delete(`${env.apiUrl}/api/program/${id}`, {
      headers,
    });
  }

  public duplicateChapter(id) {
    const headers = this.setSecureHeader();

    return this.http.get(`${env.apiUrl}/api/chapter/duplicate/${id}`, {
      headers,
    });
  }

  public duplicateProgram(data) {
    const headers = this.setSecureHeader();

    return this.http.post(`${env.apiUrl}/api/program/duplicate`, data, {
      headers,
    });
  }

  public createSection(data) {
    const headers = this.setSecureHeader();

    return this.http.post(`${env.apiUrl}/api/section/add`, data, {
      headers,
    });
  }

  public createHomeworkSection(data) {
    const headers = this.setSecureHeader();

    return this.http.post(`${env.apiUrl}/api/homework/add`, data, {
      headers,
    });
  }

  public getProgramDetail(slug) {
    const headers = this.setSecureHeader();

    return this.http.get(`${env.apiUrl}/api/program/${slug}`, {
      headers,
    });
  }

  public bookmarkProgram(id) {
    const headers = this.setSecureHeader();
    const data = { program_id: id };

    return this.http.post(`${env.apiUrl}/api/bookmark`, data, {
      headers,
    });
  }

  public archiveProgram(id) {
    const headers = this.setSecureHeader();

    return this.http.post(`${env.apiUrl}/api/learning/archive`, id, {
      headers,
    });
  }

  public getBookmarks() {
    const headers = this.setSecureHeader();

    return this.http.get(`${env.apiUrl}/api/bookmark`, {
      headers,
    });
  }

  public recommendProgram(id) {
    const headers = this.setSecureHeader();
    const data = { program_id: id };

    return this.http.post(`${env.apiUrl}/api/recommand`, data, {
      headers,
    });
  }

  public getRecommends() {
    const headers = this.setSecureHeader();

    return this.http.get(`${env.apiUrl}/api/recommand`, {
      headers,
    });
  }

  public getLearningDetail(slug) {
    const headers = this.setSecureHeader();

    return this.http.get(`${env.apiUrl}/api/learning/${slug}`, {
      headers,
    });
  }

  public getMentoringDetail(slug) {
    const headers = this.setSecureHeader();

    return this.http.get(`${env.apiUrl}/api/mentor/program/${slug}`, {
      headers,
    });
  }

  public getChapter(slug) {
    const headers = this.setSecureHeader();

    return this.http.get(
      `${env.apiUrl}/api/learning/${slug.progSlug}/${slug.chapterSlug}`,
      {
        headers,
      }
    );
  }

  public getPrice(slug, data) {
    const headers = this.setSecureHeader();

    return this.http.post(
      `${env.apiUrl}/api/learning/${slug}/calculate-price`,
      data,
      {
        headers,
      }
    );
  }

  public getMentoringChapter(slug) {
    const headers = this.setSecureHeader();

    return this.http.get(`${env.apiUrl}/api/mentor/program/${slug.progSlug}/${slug.chapterSlug}`, {
      headers,
    });
  }

  public getCategories() {
    const headers = this.setSecureHeader();

    return this.http.get(`${env.apiUrl}/api/category`, {
      headers,
    });
  }

  public getTimezones() {
    const headers = this.setSecureHeader();

    return this.http.get(`${env.apiUrl}/api/timezones`, {
      headers,
    });
  }

  public getAppCategories() {
    const headers = this.setSecureHeader();

    return this.http.get(`${env.apiUrl}/api/app-market/categories`, {
      headers,
    });
  }

  public getApps(data) {
    const headers = this.setSecureHeader();

    return this.http.post(`${env.apiUrl}/api/app-market`, data, {
      headers,
    });
  }

  public getInstalledApp() {
    const headers = this.setSecureHeader();

    return this.http.get(`${env.apiUrl}/api/app-market/installed`, {
      headers,
    });
  }

  public getProfileInsights(data = null) {
    const headers = this.setSecureHeader();

    return this.http.post(`${env.apiUrl}/api/app-market/insights/profile`, data, {
      headers,
    });
  }

  public getTraningInsights(data = null) {
    const headers = this.setSecureHeader();

    return this.http.post(`${env.apiUrl}/api/app-market/insights/training`, data, {
      headers,
    });
  }

  public getLearningInsights(data = null) {
    const headers = this.setSecureHeader();

    return this.http.post(`${env.apiUrl}/api/app-market/insights/learning`, data, {
      headers,
    });
  }

  public getMentoringInsights(data = null) {
    const headers = this.setSecureHeader();

    return this.http.post(`${env.apiUrl}/api/app-market/insights/mentoring`, data, {
      headers,
    });
  }


  public getAppDetail(slug) {
    const headers = this.setSecureHeader();

    return this.http.get(`${env.apiUrl}/api/app-market/app/${slug}`, {
      headers,
    });
  }

  public installApp(slug, data) {
    const headers = this.setSecureHeader();

    return this.http.post(`${env.apiUrl}/api/app-market/app/install/${slug}`, data, {
      headers,
    });
  }

  public uninstallApp(data) {
    const headers = this.setSecureHeader();

    return this.http.post(`${env.apiUrl}/api/app-market/app/uninstall`, data, {
      headers,
    });
  }

  public getUserSocials() {
    const headers = this.setSecureHeader();

    return this.http.get(`${env.apiUrl}/api/user/socials`, {
      headers,
    });
  }

  public getSections(id) {
    const headers = this.setSecureHeader();

    return this.http.get(`${env.apiUrl}/api/sections/${id}`, {
      headers,
    });
  }

  public getTasks(id) {
    const headers = this.setSecureHeader();

    return this.http.get(`${env.apiUrl}/api/tasks/${id}`, {
      headers,
    });
  }

  public getHomeworkSections(id) {
    const headers = this.setSecureHeader();

    return this.http.get(`${env.apiUrl}/api/homework/${id}`, {
      headers,
    });
  }

  public getStudentHomework(data) {
    const headers = this.setSecureHeader();

    return this.http.post(`${env.apiUrl}/api/homework/student`, data, {
      headers,
    });
  }

  public syncSections(data, id) {
    const headers = this.setSecureHeader();

    return this.http.post(`${env.apiUrl}/api/sections/sync/${id}`, data, {
      headers,
    });
  }

  public syncHomeworkSections(data, id) {
    const headers = this.setSecureHeader();

    return this.http.post(`${env.apiUrl}/api/homework/sync/${id}`, data, {
      headers,
    });
  }

  public deleteSection(id) {
    const headers = this.setSecureHeader();

    return this.http.delete(`${env.apiUrl}/api/sections/${id}`, {
      headers,
    });
  }

  public deleteHomeworkSection(id) {
    const headers = this.setSecureHeader();

    return this.http.delete(`${env.apiUrl}/api/homework/${id}`, {
      headers,
    });
  }

  public uploadMedia(data) {
    const formData: FormData = new FormData();
    formData.append('file', data.file);
    if (data && data.crop) {
    formData.append('x', data.crop.x);
    formData.append('y', data.crop.y);
    formData.append('height', data.crop.height);
    formData.append('width', data.crop.width);
    }
    formData.append('type', data.type);
    formData.append('program_id', data.program_id);

    const headers = this.setSecureHeader();
    return this.http.post(`${env.apiUrl}/api/media`, formData, {
      headers,
    });
  }

  uploadVideo(data: any) {

    const file = data.file;

    let info = {
      title: file.name,
      type: data.type,
      programId: data.program_id,
      sectionId: data.section_id,
      chapterId: data.chapter_id ? data.chapter_id : null,
    };

    let encTitle = (btoa(JSON.stringify(info)))

    return new Promise<any>((resolve, reject) => {
    const filename = file.name;
    let fileStatus: FileStatus = {filename, progress: 0, hash: '', uuid: ''};
    this.fileStatusArr.push(fileStatus);

    this.uploadStatus.next(this.fileStatusArr);

    var date = new Date();
    date.setDate(date.getDate() + 30);

    env.tus_header.AuthorizationExpire = String(date.getTime());

    const upload = new Upload(file, {
      endpoint: "https://video.bunnycdn.com/tusupload",
      retryDelays: [0, 3000, 6000, 12000, 24000],
      chunkSize: 20000,
      headers: env.tus_header,
      metadata: {
        title: encTitle,
        filetype: file.type
      },
      onError: async (error) => {
        console.log(error);
        resolve(false);
        return false;
      },
      onChunkComplete: (chunkSize, bytesAccepted, bytesTotal) => {
        this.fileStatusArr.forEach(value => {
          if (value.filename === filename) {
            value.progress = Math.floor(bytesAccepted / bytesTotal * 100);
            value.uuid = upload.url.split('/').slice(-1)[0];
          }
        });
        this.uploadStatus.next(this.fileStatusArr);
      },
      onSuccess: async () => {
        this.fileStatusArr.forEach(value => {
          if (value.filename === filename) {
            value.progress = 100;
            // console.log(fileStatus)
          }
        });
        resolve(fileStatus);
        this.uploadStatus.next(this.fileStatusArr);
        return true;
      }
    });
    upload.start();
    });
  }

  public getFeeds() {
    const headers = this.setSecureHeader();

    return this.http.get(`${env.apiUrl}/api/feed`, {
      headers,
    });
  }

  public getFeedSection(section) {
    const headers = this.setSecureHeader();

    return this.http.get(`${env.apiUrl}/api/feed/${section}`, {
      headers,
    });
  }


  public getCategoryFeeds(category, data = {}) {
    const headers = this.setSecureHeader();

    return this.http.post(`${env.apiUrl}/api/feed/${category}`, data, {
      headers,
    });
  }

  public getCompanyProgs(category, data = {}) {
    const headers = this.setSecureHeader();

    return this.http.post(`${env.apiUrl}/api/company/${category}`, data, {
      headers,
    });
  }


  public getMyPrograms() {
    const headers = this.setSecureHeader();

    return this.http.get(`${env.apiUrl}/api/programs/my`, {
      headers,
    });
  }

  public getMyLearnings() {
    const headers = this.setSecureHeader();

    return this.http.get(`${env.apiUrl}/api/learning`, {
      headers,
    });
  }

  public getMyMentorings() {
    const headers = this.setSecureHeader();

    return this.http.get(`${env.apiUrl}/api/mentor/programs`, {
      headers,
    });
  }

  public manageMentorInvite(data) {
    const headers = this.setSecureHeader();

    return this.http.post(`${env.apiUrl}/api/mentor/manage-invite`, data, {
      headers,
    });
  }

  public shareInvite(data) {
    const headers = this.setSecureHeader();

    return this.http.post(`${env.apiUrl}/api/invite/share`, data, {
      headers,
    });
  }

  public paymentHandshake(data) {
    const headers = this.setSecureHeader();

    return this.http.post(`${env.apiUrl}/api/pay/handshake`, data, {
      headers,
    });
  }

  public getTtansaction() {
    const headers = this.setSecureHeader();

    return this.http.get(`${env.apiUrl}/api/transaction`, {
      headers,
    });
  }

  public getInvoices() {
    const headers = this.setSecureHeader();

    return this.http.get(`${env.apiUrl}/api/invoices`, {
      headers,
    });
  }

  public requestWithdraw(data) {
    const formData: FormData = new FormData();
    formData.append('amount', data.amount);
    formData.append('file', data.file);
    const headers = this.setSecureHeader();

    return this.http.post(`${env.apiUrl}/api/withdraw/request`, formData, {
      headers,
    });
  }

  public publishProgram(data) {
    const headers = this.setSecureHeader();

    return this.http.post(`${env.apiUrl}/api/program/publish`, data, {
      headers,
    });
  }

  public purchaseProgram(data) {
    const headers = this.setSecureHeader();

    return this.http.post(`${env.apiUrl}/api/learning/purchase`, data, {
      headers,
    });
  }

  public upgradeProgram(data) {
    const headers = this.setSecureHeader();

    return this.http.post(`${env.apiUrl}/api/learning/upgrade`, data, {
      headers,
    });
  }

  public updateProgram(data) {
    const headers = this.setSecureHeader();

    return this.http.post(`${env.apiUrl}/api/learning/homework`, data, {
      headers,
    });
  }

  public updatePersonalInfo(data) {
    const formData: FormData = new FormData();
    formData.append('first_name', data.first_name);
    formData.append('last_name', data.last_name);
    formData.append('secondary_email', data.secondary_email);
    formData.append('country_id', data.country_id);
    formData.append('title', data.title);
    formData.append('headline', data.headline);
    formData.append('bank_initial', data.bank_initial);
    formData.append('bank_account', data.bank_account);
    formData.append('bio', data.bio);
    if(data && data.originalImage) {
      formData.append('avatar', data.originalImage);
    } else {
      formData.append('avatar', data.avatar);
    }
    if (data && data.crop) {
      formData.append('x', data.crop.x);
      formData.append('y', data.crop.y);
      formData.append('height', data.crop.height);
      formData.append('width', data.crop.width);
    }

    const headers = this.setSecureHeader();

    return this.http.post(`${env.apiUrl}/api/profile/update`, formData, {
      headers,
    });
  }

  public updateCompanyInfo(data) {
    const formData: FormData = new FormData();
    if (data && data.id) {
      formData.append('id', data.id);
    }
    formData.append('name', data.name);
    formData.append('admin_email', data.admin_email);
    formData.append('secondary_email', data.secondary_email);
    formData.append('country_id', data.country_id);
    formData.append('headline', data.headline);
    formData.append('bio', data.bio);
    if(data && data.originalImage) {
      formData.append('logo', data.originalImage);
    } else {
      formData.append('logo', data.logo);
    }
    if (data && data.crop) {
      formData.append('x', data.crop.x);
      formData.append('y', data.crop.y);
      formData.append('height', data.crop.height);
      formData.append('width', data.crop.width);
    }

    const headers = this.setSecureHeader();

    return this.http.post(`${env.apiUrl}/api/company`, formData, {
      headers,
    });
  }

  public respondToInvite(data) {
    const headers = this.setSecureHeader();
    const invData = {
      token: data.token,
      action: data.action,
    }
    if (data.section) {
      return this.http.post(`${env.apiUrl}/api/` + data.section + '/invite/manage', invData, {
        headers,
      });
    } else {
      return this.http.post(`${env.apiUrl}/api/invite`, invData, {
        headers,
      });
    }
  }

  public paymentStatus(data) {
    const headers = this.setSecureHeader();

    return this.http.post(`${env.apiUrl}/api/payment/status`, data, {
      headers,
    });
  }

  public investmentShowHide(data) {
    const headers = this.setSecureHeader();

    return this.http.post(`${env.apiUrl}/api/investment/update`, data, {
      headers,
    });
  }

  public addExperience(data) {
    const formData: FormData = new FormData();
    if (data && data.id) {
      formData.append('id', data.id);
    }
    formData.append('title', data.title);
    formData.append('image', data.image);
    formData.append('company_name', data.company_name);
    formData.append('start_date', data.start_date);
    formData.append('end_date', data.end_date);
    formData.append('is_working_here', data.is_working_here);
    formData.append('location', data.location);
    formData.append('about', data.about);
    formData.append('sequence', data.sequence);
    formData.append('type', data.type);

    const headers = this.setSecureHeader();

    return this.http.post(`${env.apiUrl}/api/experience/add`, formData, {
      headers,
    });
  }

  public addCertificate(data, is_for) {
    const formData: FormData = new FormData();
    if (data && data.id) {
      formData.append('certificate_id', data.id);
    }
    formData.append('image', data.certificate);
    if (data && data.end_date) {
      formData.append('end_date', data.end_date);
    }
    formData.append('description', data.description);
    if (data && data.org_name) {
      formData.append('org_name', data.org_name);
    }
    if (data && data.org_id) {
      formData.append('org_id', data.org_id);
    }
    if (data && data.issue_to) {
      formData.append('user', data.issue_to);
    }
    const headers = this.setSecureHeader();

    return this.http.post(`${env.apiUrl}/api/app-market/certificate/${is_for}`, formData, {
      headers,
    });
  }

  public convertPdfToImage(file) {
    const formData: FormData = new FormData();
    formData.append('file', file);

    const headers = this.setSecureHeader();

    return this.http.post(`${env.apiUrl}/api/pdf/image`, formData, {
      headers,
    });
  }

  public getCertificates(company_slug) {
    const headers = this.setSecureHeader();
    return this.http.get(`${env.apiUrl}/api/app-market/certificate/company/${company_slug}`, {
      headers,
    });
  }

  public deleteCertificate(id) {
    const headers = this.setSecureHeader();
    const data = { certificate_id: id };
    return this.http.post(`${env.apiUrl}/api/app-market/certificate/company/revoke`, data, {
      headers,
    });
  }

  public updateSequence(seq) {
    const data = { data: seq };
    const headers = this.setSecureHeader();

    return this.http.post(`${env.apiUrl}/api/experience/sequance/all`, data, {
      headers,
    });
  }

  public deleteExperience(id) {
    const headers = this.setSecureHeader();

    return this.http.delete(`${env.apiUrl}/api/experience/${id}`, {
      headers,
    });
  }

  public getProgramStudents(slug, data) {

    const headers = this.setSecureHeader();

    return this.http.post(`${env.apiUrl}/api/programs/${slug}/students`, data, {
      headers,
    });
  }

  public sendMessage(data) {
    const headers = this.setSecureHeader();

    return this.http.post(`${env.apiUrl}/api/send-message`, data, {
      headers,
    });
  }


  public getMessages(programId, data = {}) {
    const headers = this.setSecureHeader();

    return this.http.post(`${env.apiUrl}/api/messages/${programId}`, data, {
      headers,
    });
  }

  public readMessage(id) {
    const headers = this.setSecureHeader();

    return this.http.get(`${env.apiUrl}/api/message/read/${id}`, {
      headers,
    });
  }


  public addReference(data) {
    const formData: FormData = new FormData();
    if (data && data.id) {
      formData.append('id', data.id);
    }
    formData.append('title', data.title);
    formData.append('event_date', data.event_date);
    formData.append('description', data.description);
    formData.append('link', data.link);
    formData.append('attachment', data.attachment);
    formData.append('type', data.type);

    const headers = this.setSecureHeader();

    return this.http.post(`${env.apiUrl}/api/reference/add`, formData, {
      headers,
    });
  }

  public deleteReference(id) {
    const headers = this.setSecureHeader();

    return this.http.delete(`${env.apiUrl}/api/reference/${id}`, {
      headers,
    });
  }

  public leaveMentoring(data) {
    const headers = this.setSecureHeader();

    return this.http.post(`${env.apiUrl}/api/mentor/leave`, data, {
      headers,
    });
  }

  public removeAuthor(data) {
    const headers = this.setSecureHeader();

    return this.http.post(`${env.apiUrl}/api/author/remove`, data, {
      headers,
    });
  }

  public changeAuthor(data) {
    const headers = this.setSecureHeader();

    return this.http.post(`${env.apiUrl}/api/programs/author/change`, data, {
      headers,
    });
  }

  public deactivateAccount() {
    const headers = this.setSecureHeader();

    return this.http.get(`${env.apiUrl}/api/user/deactivate`, {
      headers,
    });
  }

  public updateSocialProfile(data) {
    const headers = this.setSecureHeader();

    return this.http.post(`${env.apiUrl}/api/profile/links`, data, {
      headers,
    });
  }

  public updateContact(data) {
    const headers = this.setSecureHeader();

    return this.http.post(`${env.apiUrl}/api/company/contact`, data, {
      headers,
    });
  }

  public shareData(data) {
    const headers = this.setSecureHeader();

    return this.http.post(`${env.apiUrl}/api/share`, data, {
      headers,
    });
  }

  public resetPromocode(data) {
    const headers = this.setSecureHeader();

    return this.http.post(`${env.apiUrl}/api/app-market/promo-code/reset`, data, {
      headers,
    });
  }

  public getSeo(slug) {
    const headers = this.setSecureHeader();

    return this.http.get(`${env.apiUrl}/api/seo/${slug}`, {
      headers,
    });
  }

  private setSecureHeader() {
    const data = this._cookies.getCookie('t');
    if (data && data.length > 0) {
      this.token = JSON.parse(atob(data));
    }


    this.authHeaders = new HttpHeaders()
      .set('dataType', 'jsonp')
      .set('Authorization', this.token ? 'Bearer ' + this.token : '');

    if (isPlatformBrowser(this.platformId)) {
      const analytic = window.visitorInfo;

      if (window.visitorId) {
        this.authHeaders = this.authHeaders.set('Visitor-ID', window.visitorId)
      }

      let platform = null;
      let vendorFlavors = null;
      let languages = null;

      this.authHeaders = this.authHeaders.set('Timezone', moment.tz.guess());
      this.authHeaders = this.authHeaders.set('Timezone-offset', moment().format('Z'));

      if (analytic && analytic.components) {
        const components = analytic.components;

        if (components.platform && components.platform.value) {
          platform = components.platform.value;
        }


        if (components.vendorFlavors && components.vendorFlavors.value) {
          vendorFlavors = components.vendorFlavors.value.join(",");
        }
        if (components.languages && components.languages.value) {
          languages = components.languages.value.join(",");
        }

        if (platform) {
          this.authHeaders = this.authHeaders.set("Platform", platform);
        }

        if (vendorFlavors) {
          this.authHeaders = this.authHeaders.set("Browser", vendorFlavors);
        }

        if (languages) {
          this.authHeaders = this.authHeaders.set("Languages", languages);
        }
      }

      this.authHeaders = this.authHeaders.set("Referer-original", document.referrer)
    } else {
      this.authHeaders = this.authHeaders.set("RequestFrom", "SeoAgent");
    }
    return this.authHeaders;
  }
}

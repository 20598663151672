import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { appRoutes } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material/material.module';
import { AuroraDirective } from './directive/aurora/aurora.directive';
import { GoogleComponent } from './pages/sso/google/google.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LinkedinComponent } from './pages/sso/linkedin/linkedin.component';
import { LinkRegisterComponent } from './pages/link-register/link-register.component';
import { PreloadAllModules, RouterModule } from '@angular/router';
import { FooterModule } from './components/footer/footer.module';
import { HeaderModule } from './components/header/header.module';
import { SnackBarComponent } from './components/snack-bar/snack-bar.component';
import { ErrorInterceptor } from './interceptor/interceptor';
import { ReactiveFormsModule } from '@angular/forms';
import { ChatModule } from './components/chat/chat.module';
import { DatePipe, DecimalPipe } from '@angular/common';
import { ShareModule } from './components/share/share.module';
import { NgxMaskModule } from 'ngx-mask';
import { RecaptchaModule, RECAPTCHA_SETTINGS } from 'ng-recaptcha';
import { environment } from 'src/environments/environment';
import { VerifyAccountComponent } from './components/verify-account/verify-account.component';
import { VerifyingComponent } from './components/verifying/verifying.component';

@NgModule({
  declarations: [
    AppComponent,
    AuroraDirective,
    GoogleComponent,
    LinkedinComponent,
    SnackBarComponent,
    LinkRegisterComponent,
    VerifyAccountComponent,
    VerifyingComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    RouterModule.forRoot(appRoutes, {
      preloadingStrategy: PreloadAllModules,
      initialNavigation: 'enabled',
      scrollPositionRestoration: 'enabled',
    }),
    BrowserAnimationsModule,
    MaterialModule,
    HttpClientModule,
    FooterModule,
    ChatModule,
    HeaderModule,
    ReactiveFormsModule,
    ShareModule,
    NgxMaskModule.forRoot({ showMaskTyped: true }),
    RecaptchaModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.recaptcha_key,
      },
    },
    DatePipe,
    DecimalPipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

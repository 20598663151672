import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { AbstractComponent } from 'src/app/Injector/abstract/abstract.component';

@Component({
  selector: 'app-iamge-preview',
  templateUrl: './iamge-preview.component.html',
  styleUrls: ['./iamge-preview.component.scss'],
})
export class IamgePreviewComponent extends AbstractComponent implements OnInit {
  @Input() open: boolean;
  @Input() imageURL: string;

  @Output() close = new EventEmitter();

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {}
}

import { moveItemInArray } from '@angular/cdk/drag-drop';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ConfirmationComponent } from 'src/app/components/confirmation/confirmation.component';
import { ApiService } from '../api/api.service';
import { ProgramService } from '../program/program.service';
import { SnackService } from '../snack/snack.service';

@Injectable({
  providedIn: 'root',
})
export class LearningService {
  public section: any = {};
  public program: any = [];
  public chapterData: any = [];
  public taskData: any = [];
  public isChat: boolean = false;
  public recommends: any = [];
  public programs: any = [];
  public promoCode: any = '';
  public havePromoCode : boolean = false;
  public price: any = {};
  public programLoaded : boolean = false;

  constructor(
    private notify: SnackService,
    private _api: ApiService,
    public dialog: MatDialog,
    private _router: Router,
    private _program: ProgramService,
  ) { }

  public getLearningDetail(slug) {
    return new Promise<void>((resolve, reject) => {
      this._api.getLearningDetail(slug).subscribe(
        (res: any) => {
          if (res.success) {
            this.program = res.data;
            this.price = this.program.price;
            this.programLoaded = true;
            resolve(this.program);
          } else {
            this.notify.error(res.message);
            this.programLoaded = true;
            resolve();
          }
        },
        (err: any) => {
          if (err.error) {
            this.notify.error(err.error.message);
            this.programLoaded = true;
            resolve();
          }
        }
      );
    });
  }

  public calculatePrice = (isFromPromo = false) => {
    const data = {
      promoCode: this.promoCode,
      isChat: this.isChat
    }

    this._api.getPrice(this.program.slug, data).subscribe(
      (res: any) => {
        if (res.success == true) {
          this.price = res.data;
        } else {
          this.price = res.data;
          this.promoCode = '';
          this.notify.error(res.message);
        }
      },
      (err: any) => {
        this.promoCode = '';
        this.price = this.program.price;
        this.notify.error(err.error.message);
      }
    )
  }

  public getChapter(slug) {
    return new Promise<void>((resolve, reject) => {
      this._api.getChapter(slug).subscribe(
        (res: any) => {
          if (res.success) {
            if(slug.chapterSlug == 'task') {
              this.taskData = res.data;
            } else {
              this.chapterData = res.data;
            }
            resolve(res.data);
          } else {
            this.notify.error(res.message);
            resolve();
          }
        },
        (err: any) => {
          if (err.error) {
            this.notify.error(err.error.message);
            resolve();
          }
        }
      );
    });
  }

  public purchaseProgram(data) {
    return new Promise((resolve, reject) => {
      this._api.purchaseProgram(data).subscribe(
        (res: any) => {
          if (res.success) {
            this.notify.success('You have enrolled for this program.');
            resolve(true);
          } else {
            this.notify.error(res.message);
            resolve(false);
          }
        },
        (err: any) => {
          if (err.error) {
            if (err.status === 402) {
              this._router.navigate(['/profile/wallet']);
            }
            this.notify.error(err.error.message);
            resolve(false);
          }
        }
      );
    });
  }

  public upgradeProgram(data) {
    return new Promise((resolve, reject) => {
      this._api.upgradeProgram(data).subscribe(
        (res: any) => {
          if (res.success) {
            resolve(true);
          } else {
            this.notify.error(res.message);
            resolve(false);
          }
        },
        (err: any) => {
          if (err.error) {
            if (err.status === 402) {
              this._router.navigate(['/profile/wallet']);
            }
            this.notify.error(err.error.message);
            resolve(false);
          }
        }
      );
    });
  }

  public updateProgram(data) {
    return new Promise((resolve, reject) => {
      this._api.updateProgram(data).subscribe(
        (res: any) => {
          if (res.success) {
            this.notify.success('You have updated your learning');
            resolve(true);
          } else {
            this.notify.error(res.message);
            resolve(false);
          }
        },
        (err: any) => {
          if (err.error) {
            this.notify.error(err.error.message);
            resolve(false);
          }
        }
      );
    });
  }

  public addSection(chapterType, type, totalChilds = null) {
    this.syncHomeworkSections(this.section, this.program.id)

    let childs: any = [];
    switch (type) {
      case 'attachments':
        childs.push({
          title: null,
          content_type: 'attachment',
          content_value: null,
        });
        break;
      case 'images':
        for (let index = 0; index < totalChilds; index++) {
          childs.push({
            content_type: 'image',
            content_value: null,
          });
        }
        break;
      case 'imagetext':
        childs.push(
          {
            content_type: 'image',
            content_value: null,
          },
          {
            content_type: 'text',
            content_value: null,
          }
        );
        break;
      case 'textimage':
        childs.push(
          {
            content_type: 'text',
            content_value: null,
          },
          {
            content_type: 'image',
            content_value: null,
          }
        );
        break;
      case 'sociallinks':
        childs.push({
          title: null,
          content_type: 'sociallink',
          content_value: null,
        });
        break;

      default:
        break;
    }

    const section = {
      title: null,
      parent_id: null,
      program_id: this.program.id,
      chapter_id: null,
      content_type: type,
      content_value: null,
      total_section: null,
      type: chapterType,
      sequence: this.section[chapterType].length,
      childs: childs,
    };

    this.createHomeworkSection(section);
  }

  public setSequence(chapterType) {
    this.section[chapterType].forEach((v, i = 0) => {
      v.sequence = i;
      i++;
    });
  }

  public createHomeworkSection(data) {
    this._api.createHomeworkSection(data).subscribe(
      (res: any) => {
        if (res.success) {
          this.section = res.data;
        } else {
          this.notify.error(res.message);
        }
      },
      (err: any) => {
        if (err.error) {
          this.notify.error(err.error.message);
        }
      }
    );
  }

  public getHomeworkSections(id) {
    this._api.getHomeworkSections(id).subscribe(
      (res: any) => {
        if (res.success) {
          this.section = res.data;
          this._program.disk_used = res.data.disk_used;
        } else {
          this.notify.error(res.message);
        }
      },
      (err: any) => {
        if (err.error) {
          this.notify.error(err.error.message);
        }
      }
    );
  }

  public syncHomeworkSections(data, id) {
    this._api.syncHomeworkSections(data, id).subscribe(
      (res: any) => {
        if (res.success) {
          this.section = res.data;
        } else {
          this.notify.error(res.message);
        }
      },
      (err: any) => {
        if (err.error) {
          this.notify.error(err.error.message);
        }
      }
    );
  }

  public removeHomeworkSection = (section, index, event) => {
    event.stopPropagation();
    this.confirmationDialog(section, index, event);
  };

  public confirmationDialog = (section, index, event) => {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      autoFocus: false,
      panelClass: 'confirmation-panel',
      data: {
        message: 'Are you sure, you want to remove this field?',
        cancel: 'No',
        confirm: 'Yes',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result === true) {
        this.deleteHomeworkSection(section);
      }
    });
  };

  public deleteHomeworkSection(section) {
    this._api.deleteHomeworkSection(section.id).subscribe(
      (res: any) => {
        if (res.success) {
          this.section = res.data;
          this.setSequence(section.type);
          this.syncHomeworkSections(this.section, this.program.id)
        } else {
          this.notify.error(res.message);
        }
      },
      (err: any) => {
        if (err.error) {
          this.notify.error(err.error.message);
        }
      }
    );
  }

  public sortHomeworkSeq(type, previousIndex, currentIndex) {
    moveItemInArray(this.section[type], previousIndex, currentIndex);
    this.setSequence(type);
  }

  public sortSequence(type) {
    const temp = [];
    this.section[type].forEach((v) => {
      temp.push(v.sequence);
    });
    temp.sort();
    this.section[type].forEach((v, i = 0) => {
      v.sequence = temp[i];
      i++;
    });
  }

  public recommendProgram(id) {
    this._api.recommendProgram(id).subscribe(
      (res: any) => {
        if (res.success) {
          this.recommends = res.data;
        } else {
          this.notify.error(res.message);
        }
      },
      (err: any) => {
        if (err.error) {
          this.notify.error(err.error.message);
        }
      }
    );
  }

  public getRecommends() {
    this._api.getRecommends().subscribe(
      (res: any) => {
        if (res.success) {
          this.recommends = res.data;
        } else {
          this.notify.error(res.message);
        }
      },
      (err: any) => {
        if (err.error) {
          this.notify.error(err.error.message);
        }
      }
    );
  }
}

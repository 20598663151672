import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AbstractComponent } from 'src/app/Injector/abstract/abstract.component';

@Component({
  selector: 'app-verify-account',
  templateUrl: './verify-account.component.html',
  styleUrls: ['./verify-account.component.scss'],
})
export class VerifyAccountComponent
  extends AbstractComponent
  implements OnInit
{
  constructor(private activatedRoute: ActivatedRoute, injector: Injector) {
    super(injector);
    this.activatedRoute.params
      .subscribe((prams) => {
        const data = {
          token: prams.token,
        };
        this._api.verifyAccount(data).subscribe(
          (res: any) => {
            if (res.success) {
              this._notify.success(res.message);
            } else {
              this._notify.error(res.message);
            }
            this._router.navigate(['/home']);
          },
          (err: any) => {
            this._router.navigate(['/home']);
            this._notify.error(err.error.message);
          }
        );
      })
      .unsubscribe();
  }

  public ngOnInit(): void {}
}

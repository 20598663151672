<div class="page-wrap d-flex flex-column custom-bg">
  <!-- <header class="custom-trans-bg">
    <nav class="navbar navbar-expand-md">
      <div class="container">
        <a
          class="navbar-brand"
          href="javascript:void(0)"
          [routerLink]="['/home']"
        >
          <img
            src="https://d1amhadyh4jroa.cloudfront.net/01061009872552557/assets/img/logo_black1.svg"
            width="140"
            height="44"
            alt=""
          />
        </a>
      </div>
    </nav>
  </header> -->
  <app-header></app-header>
  <div
    *ngIf="!isLinkSent"
    class="d-flex justify-content-center password-container"
  >
    <div class="popup-container form-container">
      <div class="forgot-heading">
        <h2 class="forgot">Forgot password?</h2>
      </div>
      <div class="forgot-ins">
        Don't worry! Just provide your registered email address, and we will
        send you a Password Reset link to your inbox.
      </div>
      <form name="forgotForm" [formGroup]="forgotForm" (ngSubmit)="requestCaptcha(gCaptcha)">
        <re-captcha size="invisible" #gCaptcha (resolved)="forgot($event)"></re-captcha>
        <div class="row g-3 align-items-center">
          <div class="col-12 pb-20px">
            <input
              type="Email"
              placeholder="Enter email address"
              class="form-control"
              formControlName="email"
            />
          </div>
          <div class="col-12">
            <button
              type="submit"
              [disabled]="!forgotForm.valid || isLoading"
              class="btn add-btn w-100"
            >
              Submit
              <mat-spinner class="float-right" *ngIf="isLoading" diameter="20"> </mat-spinner>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div
    *ngIf="isLinkSent"
    class="d-flex justify-content-center link-sent-container"
  >
    <div class="popup-container form-container">
      <div class="check-inbox-heading">
        <img
          class="mb-4"
          src="/assets/img/green-tick.svg"
          width="113"
          height="113"
          alt=""
        />
        <h2 class="check-inbox">Check your inbox!</h2>
      </div>
      <div class="check-inbox-ins">
        Following your request the Password Reset link has been sent to your
        registered email address, Go to your email inbox and click on the Reset
        link.
      </div>
    </div>
  </div>
</div>

import { EventEmitter, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import Pusher from 'pusher-js';
import { CookiesService } from '../cookies/cookies.service';

@Injectable({
  providedIn: 'root'
})
export class SocketService {
  public pusher: any;
  public token: any;

  public walletUpdate: EventEmitter<any> = new EventEmitter();
  public videoUpdate: EventEmitter<any> = new EventEmitter();
  public programChat: EventEmitter<any> = new EventEmitter();
  public messageRead: EventEmitter<any> = new EventEmitter();
  public userChannel: any;



  constructor(private _cookie: CookiesService) {
  }



  public initializeSocketConnection(user): void {

    const data = this._cookie.getCookie('t');
    if (data && data.length > 0) {
      this.token = JSON.parse(atob(data));
    }

    this.pusher = new Pusher(environment.socket.key, {
      cluster: environment.socket.key,
      disableStats: true,
      forceTLS: false,
      wsPort: environment.socket.port,
      wssPort: environment.socket.port,
      wsHost: environment.socket.host,
      authEndpoint: `${environment.apiUrl}/socket/auth`,
      auth: {
        headers: {
          'Authorization': this.token ? 'Bearer ' + this.token : '',
        }
      }
    });

    const general = this.pusher.subscribe(`private-user.notification.${user.id}`);

    general.bind('wallet-update', (data) => {
      this.walletUpdate.emit(data);
    });

    general.bind('video-update', (data) => {
      this.videoUpdate.emit(data);
    });

    this.initProgramChat(user.id);

  }

  public initProgramChat = (userId) => {
    const userChannel = this.pusher.subscribe(`private-user.chat.${userId}`);

    userChannel.bind('user-message', (data) => {
      this.programChat.emit(data);
    });

    userChannel.bind('user-message-read', (data) => {
      this.messageRead.emit(data);
    });

  }

  public walletUpdateEvent() {
    return this.walletUpdate;
  }

  public videoUpdateEvent() {
    return this.videoUpdate;
  }

  public messageEvent() {
    return this.programChat;
  }

  public messageReadEvent() {
    return this.messageRead;
  }
}

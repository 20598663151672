import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api/api.service';
import { SnackService } from 'src/app/services/snack/snack.service';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss'],
})
export class ConfirmationComponent implements OnInit {
  public data: any;
  public progress: any = 0;
  public dontShow: boolean;
  constructor(
    public dialogRef: MatDialogRef<ConfirmationComponent>,
    public dialog: MatDialog,
    private _notify: SnackService,
    private _api: ApiService,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.data = data;
    if (this.data.terms) {
      this.dontShow = true;
    }
  }

  ngOnInit(): void {
    if (this.data.appDetail) {
      this.installApp(this.data.appDetail);
    }
  }

  public decline() {
    this.dialogRef.close();
  }

  public accept() {
    if (!this.data.dontShow) {
      this.dialogRef.close(true);
    }

    if (this.data.dontShow && this.dontShow) {
      this.dialogRef.close(true);
    } else if (this.data.dontShow) {
      this.dialogRef.close();
    }
  }

  public installApp(app) {
    const data = {
      plan_id: app.plan_id,
    };
    this._api.installApp(app.slug, data).subscribe(
      (res: any) => {
        if (res.success) {
        } else {
          this._notify.error(res.message);
        }
      },
      (err: any) => {
        if (err.error) {
          this._notify.error(err.error.message);
        }
      }
    );
  }
}

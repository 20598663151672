// export const environment = {
//   production: true
// };

export const environment = {
  apiUrl: 'https://api.sebtember.com',
  production: true,
  facebook_app: 438376410972366,
  google_pub_key: "926540640125-cko9p8shlvl201jpuggr4d0nvncmphpr.apps.googleusercontent.com",
  linkedin_pub_key: "77o8u0cr4o200j",
  baseUrl: "https://sebtember.com",
  bucketUrl: "https://sebtember-static-prod.s3.us-east-2.amazonaws.com",
  stripe_pub:
    "pk_live_ZTGaJAcpvduGzz64l8ScsV4S00oBX8kFfd",
  socket: {
    key: 'asduygasuydgauysgduyg',
    cluster: 'mt1',
    port: 443,
    host: 'sebtember.com'
  },
  tus_header: {
    AccessKey: "6fdd4af0-347e-40d5-9bae5789464f-1199-4362",
    AuthorizationExpire: '',
    LibraryId: '47471',
  },
  recaptcha_key: '6LccW70hAAAAAEwk6T2MUOfZysRN2MRGlRnixJ8x',
};

import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AbstractComponent } from 'src/app/Injector/abstract/abstract.component';

@Component({
  selector: 'app-chat-list',
  templateUrl: './chat-list.component.html',
  styleUrls: ['./chat-list.component.scss']
})
export class ChatListComponent extends AbstractComponent implements OnInit {

  constructor(injector: Injector, private route: ActivatedRoute) {
    super(injector);

    this.route.data.subscribe((data) => {
    });
  }

  ngOnInit(): void {
  }

}

import { Injectable } from '@angular/core';
import { loadStripe, StripeElements } from '@stripe/stripe-js';
import { ApiService } from '../api/api.service';
import { environment as env } from 'src/environments/environment';
import { SnackService } from '../snack/snack.service';

@Injectable({
  providedIn: 'root',
})
export class StripeService {
  public isPaymentInProgress: boolean = false;
  public s: any;
  public elements: StripeElements;
  public focus: any = '';
  public float: any = {
    card: null,
    cvv: null,
    exp: null,
  };
  public error: any = {
    card: null,
    cvv: null,
    exp: null,
  };

  public form: any;

  public elementStyles: any = {
    base: {
      ':-webkit-autofill': {
        color: '#e39f48',
      },
      '::placeholder': {
        color: '#999999',
      },
      color: '#999999',
      fontSize: '12px',
      fontSmoothing: 'antialiased',
      letterSpacing: '0.48px',
      lineHeight: '2.5',
      fontWeight: 500,
    },
    invalid: {
      color: '#FF0000',

      '::placeholder': {
        color: '#FF0000',
      },
    },
  };

  constructor(private _api: ApiService, private notify: SnackService,) {
    this.initStripe();
  }

  public initStripe = async () => {
    this.s = await loadStripe(env.stripe_pub);
  };

  public initElement() {
    this.elements = this.s.elements();

    const elementClasses = {
      empty: 'empty',
      focus: 'focused',
      invalid: 'invalid',
    };

    const cardNumber = this.elements.create('cardNumber', {
      classes: elementClasses,
      style: this.elementStyles,
      placeholder: 'Add your card number',
    });
    cardNumber.mount('#sebtember-card-number');

    const cardExpiry = this.elements.create('cardExpiry', {
      classes: elementClasses,
      style: this.elementStyles,
      placeholder: 'MM/ YY',
    });
    cardExpiry.mount('#sebtember-card-expiry');

    const cardCvc = this.elements.create('cardCvc', {
      classes: elementClasses,
      style: this.elementStyles,
      placeholder: 'CVC code',
    });

    cardCvc.mount('#sebtember-card-cvc');

    this.errorListner(cardNumber, cardExpiry, cardCvc);

    this.registerElements([cardNumber, cardExpiry, cardCvc], 'payment-form');
  
  }

  public errorListner(cardNumber, cardExpiry, cardCvc) {
    cardNumber.on('focus', (event) => {
      this.focus = 'card';
    });

    cardExpiry.on('focus', (event) => {
      this.focus = 'exp';
    });

    cardCvc.on('focus', (event) => {
      this.focus = 'cvv';
    });

    cardExpiry.on('blur', (event) => {
      this.focus = '';
    });
    cardNumber.on('blur', (event) => {
      this.focus = '';
    });
    cardCvc.on('blur', (event) => {
      this.focus = '';
    });

    cardNumber.on('change', (event) => {
      this.error.card = event.error ? event.error.message : null;
      this.float.card = !event.empty;
    });

    cardExpiry.on('change', (event) => {
      this.error.exp = event.error ? event.error.message : null;
      this.float.exp = !event.empty;
    });

    cardCvc.on('change', (event) => {
      this.error.cvv = event.error ? event.error.message : null;
      this.float.cvv = !event.empty;
    });
  }

  public registerElements(elements, exampleName) {
  }

  public chargePayment(paymentMethod, sec) {
    return new Promise((resolve, reject) => {
    this.s
      .confirmCardPayment(sec, {
        payment_method: paymentMethod,
      })
      .then((result) => {
        if (result.error) {
          this.isPaymentInProgress = false;
          this.notify.error(result.error.message);
          reject(result);
        } else {
          this.isPaymentInProgress = false;
          resolve(true);
        }
      }).catch((res: any) => {
        this.isPaymentInProgress = false;
        reject(res);
      });
    });
  }


  public getCardToken(data) {
    return new Promise((resolve, reject) => {
    this.s
      .createPaymentMethod({
        billing_details: {},
        card: this.elements.getElement("cardNumber"),
        type: "card",
      })
      .then((result) => {
        if (result.error) {
          this.isPaymentInProgress = false;
          reject(result);
          // Inform the user if there was an error.
          // const errorElement = document.getElementById("card-errors");
          // errorElement.textContent = result.error.message;
        } else {
          //1 call our API to get Client Secrate
          this._api.paymentHandshake(data).subscribe(
            (res: any) => {
              if (res.success) {
                this.chargePayment(result.paymentMethod.id, res.data.client_secret).then((data: any) => {
                  if(data) {
                    resolve(true);
                  }
                }).catch((res: any) => {
                  this.isPaymentInProgress = false;
                  reject(res);
                });
              } else {
                this.isPaymentInProgress = false;
                this.notify.error(res.message);
                reject(res);
              }
            },
            (err: any) => {
              if (err.error) {
                this.isPaymentInProgress = false;
                this.notify.error(err.error.message);
                reject(err);
              }
            }
          );
        }
      }).catch((res: any) => {
        this.isPaymentInProgress = false;
        reject(res);
      });

    });
  }

}

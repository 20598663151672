import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../api/api.service';
import { SnackService } from '../snack/snack.service';
import debounce from 'lodash/debounce';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  public searchTerm: any = null;
  public searchResult: any = [];
  private deb = debounce((val) => this.fetchSearch(val), 1000, {});
  constructor(
    private _router: Router,
    private _notify: SnackService,
    private _api: ApiService
  ) {}

  public fetchSearch(term) {
    if (term !== null && term.length > 0) {
      this.fetchSearchResult(term);
    } else {
      this.searchTerm = null;
      this.searchResult = [];
    }
  }

  public onSearch(term) {
    this.deb(term);
  }

  public fetchSearchResult(term) {
    const data = { q: term };
    this._api.search(data).subscribe(
      (res: any) => {
        if (res.success) {
          this.searchResult = res.data;
        } else {
          this._notify.error(res.message);
        }
      },
      (err: any) => {
        if (err.error) {
          this._notify.error(err.error.message);
        }
      }
    );
  }

  public clearSearch() {
    this.searchTerm = null;
    this.searchResult = [];
    this._router.navigate(['/search'], { queryParams: { q: null } });
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ShareComponent } from './share.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ChartModule } from '../chart/chart.module';
import { ProgramcardModule } from '../programcard/programcard.module';
import { SafePipeModule } from 'src/app/pipes/safe/safe.pipe';

@NgModule({
  declarations: [ShareComponent],
  exports: [ShareComponent],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    ClipboardModule,
    MatIconModule,
    MatProgressSpinnerModule,
    ChartModule,
    ProgramcardModule,
    SafePipeModule
  ],
})
export class ShareModule {}

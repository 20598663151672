import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ChatComponent } from './chat.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { FormsModule } from '@angular/forms';
import { ChatListComponent } from './chat-list/chat-list.component';
import { MatMenuModule } from '@angular/material/menu';
import { AudioModule } from '../audio/audio.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { IamgePreviewModule } from '../iamge-preview/image-preview.module';

@NgModule({
  declarations: [ChatComponent, ChatListComponent],
  exports: [ChatComponent , ChatListComponent],
  imports: [
    CommonModule,
    RouterModule,
    MatExpansionModule,
    FormsModule,
    MatMenuModule,
    AudioModule,
    MatProgressSpinnerModule,
    IamgePreviewModule
  ]
})
export class ChatModule { }

<header class="custom-trans-bg">
  <nav class="navbar navbar-expand-md">
    <div class="container">
      <a
        class="navbar-brand"
        href="javascript:void(0)"
        [routerLink]="['/home']"
      >
        <img
          src="https://d1amhadyh4jroa.cloudfront.net/01061009872552557/assets/img/logo_black1.svg"
          width="140"
          height="44"
          alt=""
        />
      </a>

      <!-- <div *ngIf="header == 'login/signup'" class="heading">
        <span class="for-your"> for your </span>
        <span class="personal"> personal </span>
        <span class="growth"> growth </span>
      </div> -->
    </div>
  </nav>
</header>

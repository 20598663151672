import { Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class SeoService {
  constructor(private titleService: Title, private metaService: Meta) {}

  public setMeta(type, data) {
    this.metaService.addTags([
      {
        property: 'fb:app_id',
        content: 'fb:app_id',
      },
    ]);
    if (type == 'program') {
      const program = data;
      let photo;
      if (program.cover) {
        photo = program.cover;
      } else {
        photo = program.author.avatar;
      }
      // const title = `Sebtember ${program.name} by ${program.author.first_name} ${program.author.last_name[0]}`;
      let title, description;
      if (program.seo) {
        title = program.seo.meta_title;
        description = program.seo.description;
      }
      this.titleService.setTitle(title);
      this.metaService.addTags([
        { name: 'description', content: description },
        {
          prefix: 'og: http://ogp.me/ns#',
          property: 'og:locale',
          content: 'en_US',
        },
        {
          prefix: 'og: http://ogp.me/ns#',
          property: 'og:type',
          content: 'website',
        },
        {
          prefix: 'og: http://ogp.me/ns#',
          property: 'og:title',
          content: title,
        },
        {
          prefix: 'og: http://ogp.me/ns#',
          property: 'og:description',
          content: description,
        },
        {
          prefix: 'og: http://ogp.me/ns#',
          property: 'og:url',
          content: 'url',
        },
        {
          prefix: 'og: http://ogp.me/ns#',
          property: 'og:site_name',
          content: 'sebtember',
        },
        {
          prefix: 'og: http://ogp.me/ns#',
          property: 'og:image',
          content: photo,
        },
        {
          name: 'author',
          content: `${program.author.first_name} ${program.author.last_name[0]}`,
        },
        {
          name: 'twitter:card',
          content: 'summary_large_image',
        },
        {
          name: 'twitter:title',
          content: title,
        },
        {
          name: 'twitter:description',
          content: description,
        },
        {
          property: 'article:modified_time',
          content: `${program.updated_at}`,
        },
      ]);
    }
    if (type == 'user') {
      const user = data;
      const photo = user.avatar;
      // const title = `${user.first_name} ${user.last_name}, User at Sebtember`;
      let title, description;
      if (user.seo) {
        title = user.seo.meta_title;
        description = user.seo.description;
      }
      this.titleService.setTitle(title);
      this.metaService.addTags([
        { name: 'description', content: description },
        {
          prefix: 'og: http://ogp.me/ns#',
          property: 'og:locale',
          content: 'en_US',
        },
        {
          property: 'og:type',
          content: 'profile',
        },
        {
          prefix: 'og: http://ogp.me/ns#',
          property: 'og:title',
          content: title,
        },
        {
          prefix: 'og: http://ogp.me/ns#',
          property: 'og:description',
          content: description,
        },
        {
          prefix: 'og: http://ogp.me/ns#',
          property: 'og:url',
          content: 'url',
        },
        {
          prefix: 'og: http://ogp.me/ns#',
          property: 'og:site_name',
          content: 'sebtember',
        },
        {
          prefix: 'og: http://ogp.me/ns#',
          property: 'og:image',
          content: photo,
        },
        {
          name: 'twitter:card',
          content: 'summary_large_image',
        },
        {
          name: 'twitter:title',
          content: title,
        },
        {
          name: 'twitter:description',
          content: description,
        },
      ]);
    }
    if (type == 'company') {
      const company = data;
      const photo = company.logo;
      // const title = `${company.name}, Company-Profile`;
      let title, description;
      if (company.seo) {
        title = company.seo.meta_title;
        description = company.seo.description;
      }
      this.titleService.setTitle(title);
      this.metaService.addTags([
        { name: 'description', content: description },
        {
          prefix: 'og: http://ogp.me/ns#',
          property: 'og:locale',
          content: 'en_US',
        },
        {
          property: 'og:type',
          content: 'profile',
        },
        {
          prefix: 'og: http://ogp.me/ns#',
          property: 'og:title',
          content: title,
        },
        {
          prefix: 'og: http://ogp.me/ns#',
          property: 'og:description',
          content: description,
        },
        {
          prefix: 'og: http://ogp.me/ns#',
          property: 'og:url',
          content: 'url',
        },
        {
          prefix: 'og: http://ogp.me/ns#',
          property: 'og:site_name',
          content: 'sebtember',
        },
        {
          prefix: 'og: http://ogp.me/ns#',
          property: 'og:image',
          content: photo,
        },
        {
          name: 'twitter:card',
          content: 'summary_large_image',
        },
        {
          name: 'twitter:title',
          content: title,
        },
        {
          name: 'twitter:description',
          content: description,
        },
      ]);
    }
    if (type == 'appmarket') {
      const title = `Sebtember - App Market`;
      this.titleService.setTitle(title);
    }
    if (type == 'appdetail') {
      const app = data;
      let title, description;
      if (app.seo) {
        title = app.seo.meta_title;
        description = app.seo.description;
      }
      this.titleService.setTitle(title);
      this.metaService.addTags([
        { name: 'description', content: description },
      ])
    }
    if (type == 'home') {
      const title = `Online Learning with Certificate at Sebtember`;
      this.titleService.setTitle(title);
      this.metaService.addTags([
        { name: 'description', content: 'We offer both self-paced and instructor-led online courses on technical and leadership topics. Gain new skills and earn a certificate of completion. Join today.' },
      ])
    }
    if (type == 'about-us' || type == 'contact') {
      const seo = data;
      let title, description;
      if (seo) {
        title = seo.meta_title;
        description = seo.description;
      }
      this.titleService.setTitle(title);
      this.metaService.addTags([
        { name: 'description', content: description },
      ])
    }
  }
}

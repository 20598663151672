import { Route } from '@angular/router';
import { PaymentProcessingComponent } from './components/payment-processing/payment-processing.component';
import { VerifyAccountComponent } from './components/verify-account/verify-account.component';
import { VerifyingComponent } from './components/verifying/verifying.component';
import { CountryGuard } from './guard/country.guard';
import { RouteGuard } from './guard/route.guard';
import { LinkRegisterComponent } from './pages/link-register/link-register.component';
import { GoogleComponent } from './pages/sso/google/google.component';
import { LinkedinComponent } from './pages/sso/linkedin/linkedin.component';
import { ForgotPasswordComponent } from './pages/user/forgot-password/forgot-password.component';
import { LoginComponent } from './pages/user/login/login.component';
import { RegisterComponent } from './pages/user/register/register.component';
import { ResetPasswordComponent } from './pages/user/reset-password/reset-password.component';

export const appRoutes: Route[] = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },

  {
    path: '',
    canActivate: [],
    canActivateChild: [],
    children: [
      {
        path: 'user/verify/:token',
        component: VerifyAccountComponent,
      },
      {
        path: 'about-us',
        canActivate: [CountryGuard],
        loadChildren: () =>
          import('../app/modules/aboutus/aboutus.module').then(
            (m) => m.AboutusModule
          ),
      },
      {
        path: 'contact',
        canActivate: [CountryGuard],
        loadChildren: () =>
          import('../app/modules/contactus/contactus.module').then(
            (m) => m.ContactusModule
          ),
      },
      {
        path: 'home',
        canActivate: [CountryGuard],
        loadChildren: () =>
          import('../app/modules/home/home.module').then((m) => m.HomeModule),
      },
      {
        path: 'insights',
        canActivate: [CountryGuard],
        loadChildren: () =>
          import('../app/modules/insights/insights.module').then(
            (m) => m.InsightsModule
          ),
      },
      {
        path: 'terms-of-use',
        canActivate: [CountryGuard],
        loadChildren: () =>
          import('../app/modules/terms/terms.module').then(
            (m) => m.TermsModule
          ),
      },
      {
        path: 'profile',
        loadChildren: () =>
          import('../app/modules/profile/profile.module').then(
            (m) => m.ProfileModule
          ),
      },

      {
        path: 'user',
        loadChildren: () =>
          import('../app/modules/user/user.module').then((m) => m.UserModule),
      },

      {
        path: 'market',
        canActivate: [CountryGuard],
        loadChildren: () =>
          import('../app/modules/appmarket/appmarket.module').then(
            (m) => m.AppmarketModule
          ),
      },
    ],
  },

  {
    path: 'training',
    canActivate: [CountryGuard],
    loadChildren: () =>
      import('../app/modules/programs/programs.module').then(
        (m) => m.ProgramsModule
      ),
  },

  {
    path: 'learning',
    canActivate: [CountryGuard],
    loadChildren: () =>
      import('../app/modules/learning/learning.module').then(
        (m) => m.LearningModule
      ),
  },

  // {
  //   path: 'mentoring',
  //   canActivate: [CountryGuard],
  //   loadChildren: () =>
  //     import('../app/modules/mentoring/mentoring.module').then(
  //       (m) => m.MentoringModule
  //     ),
  // },

  {
    path: 'search',
    canActivate: [CountryGuard],
    loadChildren: () =>
      import('../app/modules/search/search.module').then((m) => m.SearchModule),
  },

  {
    path: 'community',
    canActivate: [CountryGuard],
    loadChildren: () =>
      import('../app/modules/community/community.module').then(
        (m) => m.CommunityModule
      ),
  },

  {
    path: 'students',
    canActivate: [CountryGuard],
    loadChildren: () =>
      import('../app/modules/students/students.module').then(
        (m) => m.StudentsModule
      ),
  },
  {
    path: 'sso/google',
    canActivate: [CountryGuard],
    component: GoogleComponent,
  },
  {
    path: 'sso/linkedin',
    canActivate: [CountryGuard],
    component: LinkedinComponent,
  },
  {
    path: 'login',
    canActivate: [RouteGuard],
    component: LoginComponent,
    loadChildren: () =>
      import('../app/pages/user/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'register',
    canActivate: [RouteGuard],
    component: RegisterComponent,
    loadChildren: () =>
      import('../app/pages/user/register/register.module').then(
        (m) => m.RegisterModule
      ),
  },

  {
    path: 'forgot-password',
    canActivate: [RouteGuard],
    component: ForgotPasswordComponent,
    loadChildren: () =>
      import('../app/pages/user/forgot-password/forgot-password.module').then(
        (m) => m.ForgotPasswordModule
      ),
  },

  {
    path: 'forgot-password/reset/:token',
    canActivate: [RouteGuard],
    component: ResetPasswordComponent,
    loadChildren: () =>
      import('../app/pages/user/reset-password/reset-password.module').then(
        (m) => m.ResetPasswordModule
      ),
  },

  {
    path: 'register/:code',
    canActivate: [CountryGuard],
    component: LinkRegisterComponent,
  },

  {
    canActivate: [CountryGuard],
    component: VerifyingComponent,
    path: 'invite/:token/:action',
  },

  {
    canActivate: [CountryGuard],
    component: PaymentProcessingComponent,
    path: 'payment/status/:uuid',
  },

  {
    canActivate: [CountryGuard],
    component: VerifyingComponent,
    path: ':section/invite/:token/:action',
  },
  // {
  //   // canActivate: [CountryGuard],
  //   component: CompanyProfileComponent,
  //   path: 'static/company-profile',
  // },
  {
    path: 'company',
    // canActivate: [CountryGuard],
    loadChildren: () =>
      import('./modules/company/company.module').then((m) => m.CompanyModule),
  },
  { path: '**', redirectTo: '/home' },
];

<div class="page-wrap d-flex flex-column custom-bg">
  <app-header [header]="'login/signup'"></app-header>
  <div *ngIf="!isLinkSent" class="d-flex justify-content-center content-form">
    <div class="popup-container form-container">
      <div class="signup-heading mb-3">
        <div class="login in-active" [routerLink]="['/login']">
          <h2 class="title">Login</h2>
          <p class="instruction">For registered user.</p>
        </div>
        <div class="signup active">
          <h2 class="title">Signup</h2>
          <p class="instruction">For new user.</p>
        </div>
      </div>
      <div class="sub-heading">
        <div class="individual" [ngClass]="{'active': registerFor === 'individual'}" (click)="registerFor = 'individual'">
          <h2 class="title">Individual</h2>
          <p class="instruction">Personal Account</p>
        </div>
        <div class="organization" [ngClass]="{'active': registerFor === 'organization'}" (click)="registerFor = 'organization'">
          <h2 class="title">Organization</h2>
          <p class="instruction">Company Account</p>
        </div>
      </div>
      <a href="javascript:void()" class="btn google-btn mb-3" (click)="_sso.googleRedirect()">
        <img
          class="pr-6px"
          async
          loading="lazy"
          src="/assets/img/google.svg"
          alt=""
        />
        Google
      </a>
      <a class="btn linkedin-btn" href="javascript:void()" (click)="_sso.linkedInRedirect()">
        <img
          class="pr-6px"
          async
          loading="lazy"
          src="/assets/img/linkedin.svg"
        />
        Linkedin
      </a>
      <div class="divider">
        <hr class="w-100" />
        <span class="or"> OR </span>
        <hr class="w-100" />
      </div>
      <form
        name="registerForm"
        [formGroup]="registerForm"
        (ngSubmit)="requestCaptcha(gCaptcha)"
      >
      <re-captcha size="invisible" #gCaptcha (resolved)="register($event)"></re-captcha>
        <div class="row g-3 align-items-center">
          <div class="col-6 pb-20px">
            <input
              type="text"
              placeholder="First name"
              class="form-control"
              formControlName="first_name"
            />
          </div>
          <div class="col-6 pb-20px">
            <input
              type="text"
              placeholder="Last name"
              class="form-control"
              formControlName="last_name"
            />
          </div>
          <div class="col-12 pb-20px">
            <input
              type="Email"
              placeholder="Email"
              class="form-control"
              formControlName="email"
            />
          </div>
          <!-- <div class="col-12">
            <div class="form-group">
              <mat-form-field class="fullwidth">
                <mat-label>Select country *</mat-label>
                <mat-select formControlName="country_id" class="form-control">
                  <mat-option
                    *ngFor="let country of _profile?.countries"
                    [value]="country.id"
                  >
                    {{ country.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div> -->
          <div class="col-12 pb-20px position-relative">
            <input
              [type]="hide ? 'password' : 'text'"
              placeholder="Create a Password"
              class="form-control"
              formControlName="password"
            />
            <img
              loading="lazy"
              *ngIf="!hide"
              (click)="hide = !hide"
              src="/assets/img/hide.svg"
              alt=""
              class="visibility-icon"
            />
            <img
              loading="lazy"
              *ngIf="hide"
              (click)="hide = !hide"
              src="/assets/img/show.svg"
              alt=""
              class="visibility-icon"
            />
          </div>
          <div class="col-12 pb-20px position-relative">
            <input
              [type]="hideConfirm ? 'password' : 'text'"
              placeholder="Confirm Password"
              class="form-control"
              formControlName="confirm_password"
              [ngClass]="{
                'is-invalid':
                  registerForm.controls.confirm_password.touched &&
                  f.confirm_password.errors
              }"
            />
            <img
              loading="lazy"
              *ngIf="!hideConfirm"
              (click)="hideConfirm = !hideConfirm"
              src="/assets/img/hide.svg"
              alt=""
              class="visibility-icon"
            />
            <img
              loading="lazy"
              *ngIf="hideConfirm"
              (click)="hideConfirm = !hideConfirm"
              src="/assets/img/show.svg"
              alt=""
              class="visibility-icon"
            />
          </div>
          <div class="col-12">
            <button
              type="submit"
              [disabled]="!registerForm.valid || isLoading"
              class="btn green-btn w-100"
            >
              Create Account >
              <mat-spinner class="float-right" *ngIf="isLoading" diameter="20"> </mat-spinner>
            </button>
          </div>
          <div class="privacy-policy">
            By creating a Sebtember account you are agreeing to the
            <a
              class="blue-link"
              href="javascript:void()"
              [routerLink]="['/terms-of-use']"
              >Terms of Use</a
            >
            and
            <a
              class="blue-link"
              href="javascript:void()"
              [routerLink]="['/terms-of-use']"
              >Privacy Policies.</a
            >
          </div>
        </div>
      </form>
    </div>
  </div>
  <div
    *ngIf="isLinkSent"
    class="d-flex justify-content-center link-sent-container"
  >
    <div class="popup-container form-container">
      <div class="check-inbox-heading">
        <img
          class="mb-4"
          src="/assets/img/green-tick.svg"
          width="113"
          height="113"
          alt=""
        />
        <h2 class="check-inbox">Check your inbox!</h2>
      </div>
      <div class="check-inbox-ins">
        Following your request the Account Verify link has been sent to your
        registered email address, Go to your email inbox and click on the Verify Email
        link.
      </div>
    </div>
  </div>
</div>

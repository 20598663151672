import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { SnackService } from '../snack/snack.service';

@Injectable({
  providedIn: 'root',
})
export class FeedService {
  public feeds: any = [];
  public feedCategory: any = [];
  public bookmarks: any = [];

  constructor(private notify: SnackService, private _api: ApiService) { }

  public bookmarkProgram(id) {
    this._api.bookmarkProgram(id).subscribe(
      (res: any) => {
        if (res.success) {
          this.bookmarks = res.data;
        } else {
          this.notify.error(res.message);
        }
      },
      (err: any) => {
        if (err.error) {
          this.notify.error(err.error.message);
        }
      }
    );
  }

  public getBookmarks() {
    this._api.getBookmarks().subscribe(
      (res: any) => {
        if (res.success) {
          this.bookmarks = res.data;
        } else {
          this.notify.error(res.message);
        }
      },
      (err: any) => {
        if (err.error) {
          this.notify.error(err.error.message);
        }
      }
    );
  }
}

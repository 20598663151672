import { enableProdMode } from '@angular/core';
import * as Sentry from "@sentry/angular";
import { BrowserTracing } from "@sentry/tracing";
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {

  Sentry.init({
    dsn: "https://83584020d194450c97e0f2f4fb1c9a86@o1306643.ingest.sentry.io/6549495",
    integrations: [
      new BrowserTracing({
        tracingOrigins: ["localhost", "https://api.sebtember.com/"],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });

  enableProdMode();
}

document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));
});

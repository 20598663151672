import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ApiService } from '../api/api.service';
import { SnackService } from '../snack/snack.service';

@Injectable({
  providedIn: 'root',
})
export class ShareService {

  constructor(private _notify: SnackService, private _api: ApiService) {}

  public share = (shareLink, type, program: any = {}) => {
    this.shareData(program.type, type, program.slug);

    if (type === 'facebook') {
      this.facebookShare(shareLink, program);
    } else if (type === 'linkedin') {
      this.linkedinShare(shareLink);
    } else if (type === 'pintrest') {
      this.pintrestShare(shareLink, program);
    } else if (type === 'xing') {
      this.xingShare(shareLink);
    } else if (type === 'twitter') {
      this.twitterShare(shareLink, program);
    }
  };

  public facebookShare = (url, program) => {
    const data = {
      message: `Check out ${program.name} on sebtember.com`,
      picture: program.cover_thumb,
      app_id: environment.facebook_app,
      display: 'popup',
      link: url,
    };

    let params = new URLSearchParams();
    for (let key in data) {
      params.set(key, data[key]);
    }

    window.open(`https://www.facebook.com/dialog/feed?${params}`, '_blank');
  };

  public linkedinShare = (url) => {
    window.open(
      `https://www.linkedin.com/sharing/share-offsite/?url=${url}`,
      '_blank'
    );
  };

  public pintrestShare = (url, program) => {
    const data = {
      description: `Check out ${program.name} on sebtember.com`,
      media: program.cover_thumb,
      url: url,
    };

    let params = new URLSearchParams();
    for (let key in data) {
      params.set(key, data[key]);
    }

    window.open(
      `https://www.pinterest.com/pin/create/button/?${params}`,
      '_blank'
    );
  };

  public xingShare = (url) => {
    window.open(`https://www.xing.com/social/share/spi?url=${url}`, '_blank');
  };

  public twitterShare = (url, program) => {
    const data = {
      text: `Check out ${program.name} on sebtember.com`,
      url: url,
    };

    let params = new URLSearchParams();
    for (let key in data) {
      params.set(key, data[key]);
    }

    window.open(`https://twitter.com/intent/tweet?${params}`, '_blank');
  };

  public shareData(type, social, slug) {
    const data = { type: type, social: social, slug: slug };

    this._api.shareData(data).subscribe(
      (res: any) => {
        if (res.success) {
        } else {
          this._notify.error(res.message);
        }
      },
      (err: any) => {
        if (err.error) {
          this._notify.error(err.error.message);
        }
      }
    );
  }
}

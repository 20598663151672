import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { SnackBarComponent } from "../../components/snack-bar/snack-bar.component";

@Injectable({
  providedIn: "root",
})
export class SnackService {
  private defaultConfig = {
    duration: 2000,
  };
  constructor(private snackBar: MatSnackBar) { }

  public error(message: string) {
    this.snackBar.openFromComponent(SnackBarComponent, {
      data: {
        icon: "error_outline",
        message,
      },
      panelClass: "mat-snack-error",
      ...this.defaultConfig,
    });
  }

  public success(message: string) {
    if (
      message &&
      message.length > 0 &&
      message.toLocaleLowerCase() !== "success"
    ) {
      this.snackBar.openFromComponent(SnackBarComponent, {
        data: {
          icon: "check_circle_outline",
          message,
        },
        panelClass: "mat-snack-success",
        ...this.defaultConfig,
      });
    }
  }

  public info(message: string) {
    this.snackBar.openFromComponent(SnackBarComponent, {
      data: {
        icon: "info_outline",
        message,
      },
      panelClass: "mat-snack-info",
      ...this.defaultConfig,
    });
  }

  public warning(message: string) {
    this.snackBar.openFromComponent(SnackBarComponent, {
      data: {
        icon: "warning_outline",
        message,
      },
      panelClass: "mat-snack-warning",
      ...this.defaultConfig,
    });
  }
}

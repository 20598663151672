import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AudioComponent } from './audio.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { MatSliderModule } from '@angular/material/slider';
import { FormsModule } from '@angular/forms';
import { AudioPreviewComponent } from './audio-preview/audio-preview.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  declarations: [AudioComponent, AudioPreviewComponent],
  exports: [AudioComponent, AudioPreviewComponent],
  imports: [
    CommonModule,
    RouterModule,
    NgxDropzoneModule,
    MatSliderModule,
    FormsModule,
    MatProgressSpinnerModule
  ]
})
export class AudioModule { }
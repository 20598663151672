import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Injector,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatSliderChange } from '@angular/material/slider';
import { AbstractComponent } from 'src/app/Injector/abstract/abstract.component';

@Component({
  selector: 'app-audio-preview',
  templateUrl: './audio-preview.component.html',
  styleUrls: ['./audio-preview.component.scss'],
})
export class AudioPreviewComponent extends AbstractComponent implements OnInit {
  @Input() section: any;
  @Input() public src: string;
  @Input() public autoplay: boolean = false;
  @Input() public showStateLabel: boolean = false;

  public audioProgress = 0;
  public audioStateLabel = 'Audio sample';
  public audioPreview: any;
  @Input() public volume: number = 1.0; /* 1.0 is loudest */

  @ViewChild('audioElement') public _audioRef: ElementRef;
  private audio: HTMLMediaElement;

  constructor(injector: Injector, private ref: ChangeDetectorRef) {
    super(injector);
  }

  ngOnInit(): void {
    if (this.section && this.section.message) {
      this.initAudio();
    }
  }

  public initAudio() {
    this.ref.detectChanges();

    this.audio = this._audioRef.nativeElement;
    if (this.audio) {
      this.audio.volume = this.volume;
      this.audio.autoplay = this.autoplay;

      this.audio.addEventListener('timeupdate', (currentTime) => {
        this.audioProgress = Math.floor(
          (100 / this.audio.duration) * this.audio.currentTime
        );
      });
    }
  }

  public onAudioProgressChange(event: MatSliderChange) {
    this.audio.currentTime = Math.floor(
      (this.audio.duration * event.value) / 100
    );
  }

  public pause(): void {
    if (this.audio) {
      this.audio.pause();
      this.audioStateLabel = 'Paused';
    }
  }

  public get paused(): boolean {
    if (this.audio) {
      return this.audio.paused;
    } else {
      return true;
    }
  }

  public play(): void {
    if (this.audio) {
      if (this.audio.readyState >= 2) {
        this.audio.play();
        this.audioStateLabel = 'Playing...';
      }
    }
  }

  public convertHMS(value) {
    const sec = parseInt(value, 10); // convert value to number if it's string
    let hours: any = Math.floor(sec / 3600); // get hours
    let minutes: any = Math.floor((sec - hours * 3600) / 60); // get minutes
    let seconds: any = sec - hours * 3600 - minutes * 60; //  get seconds
    // add 0 if value < 10; Example: 2 => 02
    if (hours < 10) {
      hours = '0' + hours;
    }
    if (minutes < 10) {
      minutes = '0' + minutes;
    }
    if (seconds < 10) {
      seconds = '0' + seconds;
    }
    return hours + ':' + minutes + ':' + seconds; // Return is HH : MM : SS
  }
}

import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ApiService } from '../api/api.service';
import { LearningService } from '../learning/learning.service';
import { MentoringService } from '../mentoring/mentoring.service';
import { ProgramService } from '../program/program.service';
import { SocketService } from '../socket/socket.service';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  public canChat: boolean = false;
  public programId: any;
  public section: string;
  public chatList: any = {};
  public messages: any = [];
  public chatOpen: boolean = false;
  public messageEvent = this._socket.messageEvent();
  public messageRedEvent = this._socket.messageReadEvent();


  constructor(
    private _socket: SocketService, private _api: ApiService, private _mentor: MentoringService, private _learn: LearningService, private _program: ProgramService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    if (isPlatformBrowser(this.platformId)) {

      this.messageEvent.subscribe((e) => {

        if (!this.chatOpen && e && e.section) {
          if (e.section == 'mentor') {
            this.programId = e.program_id;
            const user = find(this._mentor.program.students, { from_user_id: e.from_user_id });
            if (user) {
              this.section = 'mentoring';
              this.chatList = user.from_user;
              this.chatOpen = true;
            }
          } else if (e.section == 'mentoring') {
            this.programId = e.program_id;
            const user = find(this._learn.program.mentors, { user_id: e.from_user_id });
            if (user) {
              this.section = 'mentor';
              this.chatList = user.user;
              this.chatOpen = true;
            }
          } else if (e.section == 'author') {
            this.programId = e.program_id;
            const user = find(this._program.program.students, { user_id: e.from_user_id });
            if (user) {
              this.section = 'training';
              this.chatList = user.user;
              this.chatOpen = true;
            }
          } else if (e.section == 'training') {
            this.programId = e.program_id;

            if (this._learn.program.author.id == e.from_user_id) {
              this.section = 'author';
              this.chatList = this._learn.program.author;
              this.chatOpen = true;
            }
          } else if (e.section == 'co-author') {
            this.programId = e.program_id;
            const user = find(this._program.program.authors, { id: e.from_user_id });
            if (user) {
              this.section = 'co-author';
              this.chatList = user;
              this.chatOpen = true;
            }
          }
        }


        if (this.chatOpen && (this._learn.program.id == e.program_id || this._program.program.id == e.program_id || this._mentor.program.id == e.program_id)) {
          this.messages.push(e);

          this._api.readMessage(e.id).subscribe((res: any) => {
            let i = findIndex(this.messages, { id: e.id });
            this.messages[i] = res.data;
          }, (err: any) => {

          });

          this.scrollUpdate();
        }


      });

      this.messageRedEvent.subscribe((e) => {
        let i = findIndex(this.messages, { id: e.id });
        this.messages[i] = e;
      });
    }
  }

  public destoryChat() {
    this.canChat = false;
    this.messageEvent.unsubscribe();
  }

  public loadChat() {
    const userId = this.chatList.id;
    this._api.getMessages(this.programId, { user: userId }).subscribe((res: any) => {
      this.messages = res.data;
      this.scrollUpdate();
    }, (err: any) => {

    })
  }

  public sendMessage(data) {
    data.section = this.section;
    this._api.sendMessage(data).subscribe((res: any) => {
      this.messages.push(res.data);
      this.scrollUpdate();
    }, (err: any) => {

    })
  }

  public openChat(user, programId, section) {
    this.closeChat();

    setTimeout(() => {
      this.programId = programId;
      this.chatOpen = true;
      this.section = section;
      this.chatList = user;
    }, 1);
  }

  public closeChat() {
    this.messages = [];
    this.section = null;
    this.chatList = false;
    this.chatOpen = false;
  }

  public scrollUpdate = () => {
    if (isPlatformBrowser(this.platformId)) {
      setTimeout(() => {
        const view = document.getElementsByClassName('chat-view-list');
        document.getElementsByClassName('chat-view-list')[0].scrollTop = view[0].scrollHeight;
      }, 1);
    }

  }


}

<div class="page-wrap d-flex flex-column custom-bg">
  <app-header></app-header>
  <div class="d-flex justify-content-center password-container">
    <div *ngIf="isValidToken" class="popup-container form-container">
      <div class="reset-heading">
        <h2 class="forgot">Create New Password!</h2>
      </div>
      <form name="resetForm" [formGroup]="resetForm" (ngSubmit)="requestCaptcha(gCaptcha)">
        <re-captcha size="invisible" #gCaptcha (resolved)="reset($event)"></re-captcha>
        <div class="row g-3 align-items-center">
          <div class="col-12 pb-20px position-relative">
            <input
              [type]="hide ? 'password' : 'text'"
              placeholder="Enter New Password"
              class="form-control"
              formControlName="password"
            />
            <img
              loading="lazy"
              *ngIf="!hide"
              (click)="hide = !hide"
              src="/assets/img/hide.svg"
              alt=""
              class="visibility-icon"
            />
            <img
              loading="lazy"
              *ngIf="hide"
              (click)="hide = !hide"
              src="/assets/img/show.svg"
              alt=""
              class="visibility-icon"
            />
          </div>
          <div class="col-12 pb-20px position-relative">
            <input
              [type]="hideConfirm ? 'password' : 'text'"
              placeholder="Confirm New Password"
              class="form-control"
              formControlName="confirm_password"
              [ngClass]="{
                'is-invalid':
                  resetForm.controls.confirm_password.touched &&
                  f.confirm_password.errors
              }"
            />
            <img
              loading="lazy"
              *ngIf="!hideConfirm"
              (click)="hideConfirm = !hideConfirm"
              src="/assets/img/hide.svg"
              alt=""
              class="visibility-icon"
            />
            <img
              loading="lazy"
              *ngIf="hideConfirm"
              (click)="hideConfirm = !hideConfirm"
              src="/assets/img/show.svg"
              alt=""
              class="visibility-icon"
            />
          </div>

          <div class="col-12">
            <button
              type="submit"
              [disabled]="!resetForm.valid || isLoading"
              class="btn add-btn w-100"
            >
              Submit
              <mat-spinner class="float-right" *ngIf="isLoading" diameter="20"> </mat-spinner>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

import { moveItemInArray } from '@angular/cdk/drag-drop';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ConfirmationComponent } from 'src/app/components/confirmation/confirmation.component';
import { ApiService } from '../api/api.service';
import { SnackService } from '../snack/snack.service';

@Injectable({
  providedIn: 'root',
})
export class ProgramService {
  public program: any = [];
  public apps: any = [];
  public promocode: any = [];
  public isPreview: boolean = false;
  public isTaskPreview: boolean = false;
  public isRecentPublished: boolean = false;
  public programs: any = [];
  public disk_used: any;
  public section: any = {};

  constructor(
    public dialog: MatDialog,
    private notify: SnackService,
    private _api: ApiService,
    private _router: Router
  ) {}

  public createProgram(data, isPreview) {
    return new Promise<void>((resolve, reject) => {
      this._api.createProgram(data).subscribe(
        (res: any) => {
          if (res.success) {
            this.program = res.data.program;
            this.apps = res.data.apps;
            this.promocode = res.data.promocode;
            if (!isPreview && !data.id) {
              this.notify.success('Program created successfully');
            }
            resolve(this.program);
          } else {
            this.notify.error(res.message);
            resolve();
          }
        },
        (err: any) => {
          if (err.error) {
            this.notify.error(err.error.message);
            resolve();
          }
        }
      );
    });
  }

  public getProgramDetail(slug) {
    return new Promise<void>((resolve, reject) => {
      this._api.getProgramDetail(slug).subscribe(
        (res: any) => {
          if (res.success) {
            this.program = res.data.program;
            this.apps = res.data.apps;
            this.promocode = res.data.promocode;
            resolve(this.program);
          } else {
            this.notify.error(res.message);
            resolve();
          }
        },
        (err: any) => {
          if (err.error) {
            this.notify.error(err.error.message);
            resolve();
          }
        }
      );
    });
  }

  public getCategories() {
    return new Promise<void>((resolve, reject) => {
      this._api.getCategories().subscribe(
        (res: any) => {
          if (res.success) {
            resolve(res.data);
          } else {
            this.notify.error(res.message);
            resolve();
          }
        },
        (err: any) => {
          if (err.error) {
            this.notify.error(err.error.message);
            resolve();
          }
        }
      );
    });
  }

  public getTimezones() {
    return new Promise<void>((resolve, reject) => {
      this._api.getTimezones().subscribe(
        (res: any) => {
          if (res.success) {
            resolve(res.data);
          } else {
            this.notify.error(res.message);
            resolve();
          }
        },
        (err: any) => {
          if (err.error) {
            this.notify.error(err.error.message);
            resolve();
          }
        }
      );
    });
  }

  public deleteProgram(id) {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      autoFocus: false,
      panelClass: 'confirmation-panel',
      data: {
        message: 'Are you sure, you want to delete this program?',
        cancel: 'No',
        confirm: 'Yes',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result === true) {
        this._api.deleteProgram(id).subscribe(
          (res: any) => {
            if (res.success) {
              this.programs = res.data;
              this.notify.success(res.message);
              this._router.navigateByUrl('/training/my');
            } else {
              this.notify.error(res.message);
            }
          },
          (err: any) => {
            if (err.error) {
              this.notify.error(err.error.message);
            }
          }
        );
      }
    });
  }

  public removeAuthor(program_user_id, program_id) {
    const data = {
      program_user_id: program_user_id,
      program_id: program_id,
    };
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      autoFocus: false,
      panelClass: 'confirmation-panel',
      data: {
        message: 'Are you sure, you want to remove this Co-Author?',
        cancel: 'No',
        confirm: 'Yes',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result === true) {
        this._api.removeAuthor(data).subscribe(
          (res: any) => {
            if (res.success) {
              this.notify.success(res.message);
            } else {
              this.notify.error(res.message);
            }
          },
          (err: any) => {
            if (err.error) {
              this.notify.error(err.error.message);
            }
          }
        );
      }
    });
  }

  public createSection(data) {
    this._api.createSection(data).subscribe(
      (res: any) => {
        if (res.success) {
          this.section = res.data;
        } else {
          this.notify.error(res.message);
        }
      },
      (err: any) => {
        if (err.error) {
          this.notify.error(err.error.message);
        }
      }
    );
  }

  public getTasks(id) {
    this._api.getTasks(id).subscribe(
      (res: any) => {
        if (res.success) {
          this.section = res.data;
          this.disk_used = res.data.disk_used;
        } else {
          this.notify.error(res.message);
        }
      },
      (err: any) => {
        if (err.error) {
          this.notify.error(err.error.message);
        }
      }
    );
  }

  public deleteSection(section) {
    this._api.deleteSection(section.id).subscribe(
      (res: any) => {
        if (res.success) {
          this.section = res.data;
          this.setSequence(section.type);
          this.syncSections(this.section, this.program.id);
        } else {
          this.notify.error(res.message);
        }
      },
      (err: any) => {
        if (err.error) {
          this.notify.error(err.error.message);
        }
      }
    );
  }

  public uploadMedia(data) {
    return new Promise<void>((resolve, reject) => {
      this._api.uploadMedia(data).subscribe(
        (res: any) => {
          if (res.success) {
            resolve(res.data);
          } else {
            this.notify.error(res.message);
            resolve();
          }
        },
        (err: any) => {
          if (err.error) {
            this.notify.error(err.error.message);
            resolve();
          }
        }
      );
    });
  }

  public syncSections(data, id) {
    this._api.syncSections(data, id).subscribe(
      (res: any) => {
        if (res.success) {
          this.section = res.data;
        } else {
          this.notify.error(res.message);
        }
      },
      (err: any) => {
        if (err.error) {
          this.notify.error(err.error.message);
        }
      }
    );
  }

  public addSection(chapterType, type, totalChilds = null) {
    this.syncSections(this.section, this.program.id);

    let childs: any = [];
    switch (type) {
      case 'attachments':
        childs.push({
          title: null,
          content_type: 'attachment',
          content_value: null,
        });
        break;
      case 'images':
        for (let index = 0; index < totalChilds; index++) {
          childs.push({
            content_type: 'image',
            content_value: null,
          });
        }
        break;
      case 'imagetext':
        childs.push(
          {
            content_type: 'image',
            content_value: null,
          },
          {
            content_type: 'text',
            content_value: null,
          }
        );
        break;
      case 'textimage':
        childs.push(
          {
            content_type: 'text',
            content_value: null,
          },
          {
            content_type: 'image',
            content_value: null,
          }
        );
        break;
      case 'sociallinks':
        childs.push({
          title: null,
          content_type: 'sociallink',
          content_value: null,
        });
        break;
      case 'itemproduct':
        childs.push(
          {
            content_type: 'link',
            content_value: null,
          },
          {
            content_type: 'image',
            content_value: null,
          },
          {
            content_type: 'title',
            content_value: null,
          },
          {
            content_type: 'description',
            content_value: null,
          }
        );
        break;

      default:
        break;
    }

    const section = {
      title: null,
      parent_id: null,
      program_id: this.program.id,
      chapter_id: null,
      content_type: type,
      content_value: null,
      total_section: null,
      type: chapterType,
      sequence: this.section[chapterType].length,
      childs: childs,
    };

    this.createSection(section);
  }

  public setSequence(chapterType) {
    this.section[chapterType].forEach((v, i = 0) => {
      v.sequence = i;
      i++;
    });
  }

  public removeSection = (section, index, event) => {
    event.stopPropagation();
    this.confirmationDialog(section, index, event);
  };

  public confirmationDialog = (section, index, event) => {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      autoFocus: false,
      panelClass: 'confirmation-panel',
      data: {
        message: 'Are you sure, you want to remove this field?',
        cancel: 'No',
        confirm: 'Yes',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result === true) {
        this.deleteSection(section);
      }
    });
  };

  public sortSeq(type, previousIndex, currentIndex) {
    moveItemInArray(this.section[type], previousIndex, currentIndex);
    this.setSequence(type);
  }

  public sortSequence(type) {
    const temp = [];
    this.section[type].forEach((v) => {
      temp.push(v.sequence);
    });
    temp.sort();
    this.section[type].forEach((v, i = 0) => {
      v.sequence = temp[i];
      i++;
    });
  }

  public duplicateProgram(id) {
    const data = { program_id: id };
    this._api.duplicateProgram(data).subscribe(
      (res: any) => {
        if (res.success) {
          this.notify.success('Program duplicate successfully');
          this._router.navigate(['/training', res.data, 'edit']);
        } else {
          this.notify.error(res.message);
        }
      },
      (err: any) => {
        if (err.error) {
          this.notify.error(err.error.message);
        }
      }
    );
  }
}

import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ConfirmationComponent } from 'src/app/components/confirmation/confirmation.component';
import { ApiService } from '../api/api.service';
import { SnackService } from '../snack/snack.service';

@Injectable({
  providedIn: 'root',
})
export class MentoringService {
  public recommends: any = [];
  public section: any = {};
  public program: any = [];
  public programs: any = [];
  public chapterData: any = [];
  public programLoaded : boolean = false;

  constructor(
    public dialog: MatDialog,
    private notify: SnackService,
    private _api: ApiService,
    private _router: Router
  ) {}

  public recommendProgram(id) {
    this._api.recommendProgram(id).subscribe(
      (res: any) => {
        if (res.success) {
          this.recommends = res.data;
        } else {
          this.notify.error(res.message);
        }
      },
      (err: any) => {
        if (err.error) {
          this.notify.error(err.error.message);
        }
      }
    );
  }

  public getRecommends() {
    this._api.getRecommends().subscribe(
      (res: any) => {
        if (res.success) {
          this.recommends = res.data;
        } else {
          this.notify.error(res.message);
        }
      },
      (err: any) => {
        if (err.error) {
          this.notify.error(err.error.message);
        }
      }
    );
  }

  public getMentoringDetail(slug) {
    return new Promise<void>((resolve, reject) => {
      this._api.getMentoringDetail(slug).subscribe(
        (res: any) => {
          if (res.success) {
            this.program = res.data;
            this.programLoaded = true;
            resolve(this.program);
          } else {
            this.notify.error(res.message);
            this.programLoaded = true;
            resolve();
          }
        },
        (err: any) => {
          if (err.error) {
            this.notify.error(err.error.message);
            this.programLoaded = true;
            resolve();
          }
        }
      );
    });
  }

  public getChapter(slug) {
    return new Promise<void>((resolve, reject) => {
      this._api.getMentoringChapter(slug).subscribe(
        (res: any) => {
          if (res.success) {
            this.chapterData = res.data;
            resolve(this.program);
          } else {
            this.notify.error(res.message);
            resolve();
          }
        },
        (err: any) => {
          if (err.error) {
            this.notify.error(err.error.message);
            resolve();
          }
        }
      );
    });
  }

  public getHomeworkSections(id) {
    this._api.getHomeworkSections(id).subscribe(
      (res: any) => {
        if (res.success) {
          this.section = res.data;
        } else {
          this.notify.error(res.message);
        }
      },
      (err: any) => {
        if (err.error) {
          this.notify.error(err.error.message);
        }
      }
    );
  }

  public leaveMentoring(program_id, from_user_id) {
    const data = {
      program_id: program_id,
      from_user_id: from_user_id,
    };
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      autoFocus: false,
      panelClass: 'confirmation-panel',
      data: {
        message: 'Are you sure, you want to leave this mentoring?',
        cancel: 'No',
        confirm: 'Yes',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result === true) {
        this._api.leaveMentoring(data).subscribe(
          (res: any) => {
            if (res.success) {
              this.notify.success('Mentoring left successfully');
              this._router.navigateByUrl('/mentoring/my');
            } else {
              this.notify.error(res.message);
            }
          },
          (err: any) => {
            if (err.error) {
              this.notify.error(err.error.message);
            }
          }
        );
      }
    });
  }
}

import { Component, Injector, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AbstractComponent } from 'src/app/Injector/abstract/abstract.component';
import { CustomValidators } from 'src/app/providers/CustomValidators';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['../common/common.scss'],
})
export class ResetPasswordComponent
  extends AbstractComponent
  implements OnInit
{
  public resetForm: FormGroup;
  public isLoading: boolean = false;
  public isValidToken: boolean = false;
  public hide: boolean = true;
  public hideConfirm: boolean = true;
  public token: any;
  constructor(
    injector: Injector,
    public _formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute
  ) {
    super(injector);
    this.activatedRoute.params
      .subscribe((prams) => {
        this.token = prams.token;
        this._api.verifyToken(prams.token).subscribe(
          (res: any) => {
            if (res.success) {
              this.isValidToken = true;
              // this._notify.success(res.message);
            } else {
              this._notify.error(res.message);
              this._router.navigate(['/forgot-password']);
            }
          },
          (err: any) => {
            this._router.navigate(['/forgot-password']);
            this._notify.error(err.error.message);
          }
        );
      })
      .unsubscribe();
  }

  ngOnInit(): void {
    this.initresetForm();
  }

  public initresetForm = () => {
    this.resetForm = new FormGroup(
      {
        recaptcha_token: new FormControl(''),
        password: new FormControl('', [Validators.required]),
        confirm_password: new FormControl('', [Validators.required]),
      },

      CustomValidators.mustMatch('password', 'confirm_password')
    );
  };

  public reset(e) {
    if (!this.resetForm.invalid) {
      this.resetForm.patchValue({ recaptcha_token: e });
      this.resetSubmit();
    }
  }

  public requestCaptcha(captcha) {
    if (!this.resetForm.invalid) {
      this.isLoading = true;
      captcha.execute();
    }
  }

  public resetSubmit = () => {
    const data = this.resetForm.getRawValue();
    data.token = this.token;
    this._api.updatePassword(data).subscribe(
      (res: any) => {
        if (res.success) {
          this.isLoading = false;
          this._notify.success(res.message);
          this._router.navigate(['/login']);
        } else {
          this.isLoading = false;
          this._notify.error(res.message);
        }
      },
      (err: any) => {
        if (err.error) {
          this.isLoading = false;
          this._notify.error(err.error.message);
        }
      }
    );
  };

  get f() {
    return this.resetForm.controls;
  }
}

<div class="payment-processing-container">
  <div class="payment-processing-content">
    <div class="lds-ring">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    <h3 class="pt-5">Payment in progress</h3>
  </div>
</div>

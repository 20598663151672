<div class="custom-snackbar">
  <mat-icon class="icon">
    {{ data?.icon }}
  </mat-icon>
  <span class="message">
    {{ data?.message }}
  </span>
  <span class="close">
    <mat-icon class="icon close" (click)="snackBarRef.dismiss()">close</mat-icon>
  </span>
</div>

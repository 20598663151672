<div class="m-4">
  <h5 class="title">{{ data.title }}</h5>
  <p>
    {{ data.message }}
  </p>
  <p>
    {{ data.moreInfo }}
  </p>
  <mat-progress-bar *ngIf="data.isInstall"
      class="installation-progress"
      mode="determinate"
      [value]="progress">
  </mat-progress-bar>
  <div *ngIf="data.dontShow" class="custom-checkbox">
    <input
      type="checkbox"
      id="checkbox"
      name="pa-radio"
      [(ngModel)]="dontShow"
    />
    <label class="form-check-label" for="checkbox">
      <span>
        <i class="icon-done icon-white eight-font"></i>
      </span>
      {{data.dontShow}}
      <a *ngIf="data.displayTerms" href="javascript:void(0)" class="terms-link" (click)="dialogRef.close()" [routerLink]="['/terms-of-use']">{{data.terms}}</a>
    </label>
  </div>
</div>
<div class="float-right mb-4 mr-4">
  <button *ngIf="data.cancel" class="btn cancel-btn mr-3" (click)="decline()">{{data.cancel}}</button>
  <button [disabled]="data.terms && !dontShow" *ngIf="data.confirm" class="btn medium-btn" (click)="accept()">{{data.confirm}}</button>
</div>

<mat-accordion>
  <mat-expansion-panel expanded hideToggle (opened)="panelOpenState = true" (closed)="panelOpenState = false">
    <mat-expansion-panel-header>
      <h2 class="d-flex justify-content-cente w-100">
        <div class="btn-circle rotate" [ngClass]="{'down': !panelOpenState}">
          <i class="icon-arrow-down thirty-font icon-white"></i>
        </div>
        <small class="flex-grow-1">{{user.first_name}} {{user.last_name}}</small>
        <div class="btn-circle" (click)="$event.stopPropagation(); _chat.closeChat()">
          <i class="icon-close twenty-four icon-white"></i>
        </div>
      </h2>
    </mat-expansion-panel-header>
    <div class="chat-view-list" #chatContainer>
      <!-- <div class="chat-date text-center">Tuesday 20:21</div> -->
      <div class="same-msg" *ngFor="let msg of _chat.messages"
        [ngClass]="{'send-msg' : msg.from_user_id == _user.session.user.id, 'receive-msg' : msg.to_user_id == _user.session.id}">
        <div class="chat-txt">
          <p *ngIf="msg.type == 'text'">{{msg.message}}</p>
          <div *ngIf="msg.type !== 'text'" class="attachment">
            <div *ngIf="msg.type == 'image'">
              <app-iamge-preview [imageURL]="msg.message" [open]="viewerOpen" (close)="viewerOpen = false"></app-iamge-preview>
              <img [src]="msg.message" (click)="viewerOpen = true">
            </div>
            <div class="d-inline-flex" *ngIf="msg.type == 'audio'">
              <app-audio-preview [section]="msg" [view]="'chat'">
              </app-audio-preview>
            </div>
            <div *ngIf="msg.type == 'video'">
              <video controls class="w-100" [src]="msg.message"></video>
            </div>
          </div>
        </div>
        <small *ngIf="msg.read_at && msg.from_user_id == _user.session.user.id">Read</small>
      </div>

      <div class="same-msg" *ngFor="let progress of inProgress ; let i = index;" class="send-msg">
        <div class="chat-txt">
          <div>
            <div>
              <mat-spinner diameter="20"></mat-spinner>
            </div>
            Uploading ...
          </div>
        </div>
        <small>&nbsp;&nbsp;</small>
      </div>

    </div>
    <div class="chat-bottom">
      <div class="btn-circle">
        <i class="icon-attach-new twenty-four" [matMenuTriggerFor]="attachmentOption"></i>
        <mat-menu #attachmentOption="matMenu" class="chpOptions-dropdown">
          <ul>
            <li class="subopen">
              <button (click)="audio.click()">
                <i class="icon-audio-new twenty-four"></i> Audio
              </button>
              <input type="file" accept="audio/*" #audio class="hidden" onclick="this.value = null"
                (change)="uploadFile($event, 'audio')">
            </li>
            <li class="subopen">
              <button (click)="image.click()">
                <i class="icon-image twenty-four"></i>Image
              </button>
              <input type="file" accept="image/*" #image class="hidden" onclick="this.value = null"
                (change)="uploadFile($event, 'image')">
            </li>
            <li class="subopen">
              <button (click)="video.click()">
                <i class="icon-video twenty-four"></i>Video
              </button>
              <input type="file" accept="video/*" #video class="hidden" onclick="this.value = null"
                (change)="uploadFile($event, 'video')">
            </li>

          </ul>
        </mat-menu>
      </div>

      <textarea #chatInput (keydown.shift.enter)="sendMessage($event)" (keydown)="textAreaAdjust()" [(ngModel)]="message" class="form-control" rows="1"
        placeholder="Post message"></textarea>
      <div class="btn-circle send-circle">
        <button class="send-ico" (click)="sendMessage()" ></button>
      </div>
    </div>
  </mat-expansion-panel>
</mat-accordion>
import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { SnackService } from '../snack/snack.service';

@Injectable({
  providedIn: 'root',
})
export class AppMarketService {
  public appDetail: any;
  constructor(private notify: SnackService, private _api: ApiService) {}

  public getAppCategories() {
    return new Promise<void>((resolve, reject) => {
      this._api.getAppCategories().subscribe(
        (res: any) => {
          if (res.success) {
            resolve(res.data);
          } else {
            this.notify.error(res.message);
            resolve();
          }
        },
        (err: any) => {
          if (err.error) {
            this.notify.error(err.error.message);
            resolve();
          }
        }
      );
    });
  }
}

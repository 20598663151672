import { Component, Injector, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AbstractComponent } from 'src/app/Injector/abstract/abstract.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['../common/common.scss'],
})
export class LoginComponent extends AbstractComponent implements OnInit {
  public signupForm: FormGroup;
  public isLoading: boolean = false;
  public hide: boolean = true;
  public hideConfirm: boolean = true;
  constructor(injector: Injector, public _formBuilder: FormBuilder) {
    super(injector);
  }

  ngOnInit(): void {
    this.initSignupForm();
  }

  public initSignupForm = () => {
    this.signupForm = this._formBuilder.group({
      recaptcha_token: '',
      email: [, [Validators.required, Validators.email]],
      password: [, [Validators.required]],
    });
  };

  public signup(e) {
    if (!this.signupForm.invalid) {
      this.signupForm.patchValue({ recaptcha_token: e });
      this.signupSubmit();
    }
  }

  public requestCaptcha(captcha) {
    if (!this.signupForm.invalid) {
      this.isLoading = true;
      captcha.execute();
    }
  }

  public signupSubmit = () => {
    this.isLoading = true;
    const data = this.signupForm.getRawValue();
    this._api.userLogin(data).subscribe((res: any) => {
      if (res.success) {
        this._cookies.setCookie('t', btoa(JSON.stringify(res.data.token)), 1);
        if (!this._user.session) {
          this._api.me().subscribe(
            (res: any) => {
              if (res.success) {
                this.isLoading = false;
                this._user.session = res.data;
                this._socket.initializeSocketConnection(res.data.user);
                this._app.getInstalledApp();
                this._notify.success(res.message);
              } else {
                this.isLoading = false;
                this._notify.error(res.message);
              }
            },
            (err: any) => {
              this._notify.error(err.error.message);
              this.isLoading = false;
            }
          );
          this._com.redirect();
          // if (res.data.recently_created) {
          //   this._notify.success('Thank you. Check your e-mail inbox.');
          // }
        }
      } else {
        this._notify.error(res.message);
        this.isLoading = false;
      }
    },
    (err: any) => {
      this._notify.error(err.error.message);
      this.isLoading = false;
    });
  };
}

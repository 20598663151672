import { Component, Injector, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AbstractComponent } from 'src/app/Injector/abstract/abstract.component';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['../common/common.scss'],
})
export class ForgotPasswordComponent
  extends AbstractComponent
  implements OnInit
{
  public forgotForm: FormGroup;
  public isLoading: boolean = false;
  public isLinkSent: boolean = false;
  public hide: boolean = true;
  public hideConfirm: boolean = true;
  constructor(injector: Injector, public _formBuilder: FormBuilder) {
    super(injector);
  }

  ngOnInit(): void {
    this.initForgotForm();
  }

  public initForgotForm = () => {
    this.forgotForm = this._formBuilder.group({
      recaptcha_token: '',
      email: [, [Validators.required, Validators.email]],
    });
  };

  public forgot(e) {
    if (!this.forgotForm.invalid) {
      this.forgotForm.patchValue({ recaptcha_token: e });
      this.forgotSubmit();
    }
  }

  public requestCaptcha(captcha) {
    if (!this.forgotForm.invalid) {
      this.isLoading = true;
      captcha.execute();
    }
  }

  public forgotSubmit = () => {
    this.isLoading = true;
    const data = this.forgotForm.getRawValue();
    this._api.forgotPassword(data).subscribe(
      (res: any) => {
        if (res.success) {
          this.isLoading = false;
          this.isLinkSent = true;
          this._notify.success(res.message);
        } else {
          this.isLoading = false;
          this._notify.error(res.message);
        }
      },
      (err: any) => {
        if (err.error) {
          this.isLoading = false;
          this._notify.error(err.error.message);
        }
      }
    );
  };
}

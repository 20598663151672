import { Component } from '@angular/core';
import { ChatService } from './services/chat/chat.service';
import { CommonService } from './services/common/common.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'sebtember';

  constructor(public _com: CommonService, public _chat: ChatService) { }
}

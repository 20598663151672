<div class="page-wrap d-flex flex-column custom-bg">
  <app-header [header]="'login/signup'"></app-header>
  <div class="d-flex justify-content-center login-container">
    <div class="popup-container form-container">
      <div class="login-heading">
        <div class="login active">
          <h2 class="title">Login</h2>
          <p class="instruction">For registered user.</p>
        </div>
        <div class="signup in-active" [routerLink]="['/register']">
          <h2 class="title">Signup</h2>
          <p class="instruction">For new user.</p>
        </div>
        <!-- <a
          href="javascript:void()"
          class="blue-link login"
          [routerLink]="['/register']"
        >
          New user? Get an Account
        </a> -->
      </div>
      <a href="javascript:void()" class="btn google-btn mb-3" (click)="_sso.googleRedirect()">
        <img
          class="pr-6px"
          async
          loading="lazy"
          src="/assets/img/google.svg"
          alt=""
        />
        Google
      </a>
      <a class="btn linkedin-btn" href="javascript:void()" (click)="_sso.linkedInRedirect()">
        <img
          class="pr-6px"
          async
          loading="lazy"
          src="/assets/img/linkedin.svg"
        />
        Linkedin
      </a>
      <div class="divider">
        <hr class="w-100" />
        <span class="or"> OR </span>
        <hr class="w-100" />
      </div>
      <form name="signupForm" [formGroup]="signupForm" (ngSubmit)="requestCaptcha(gCaptcha)">
        <re-captcha size="invisible" #gCaptcha (resolved)="signup($event)"></re-captcha>
        <div class="row g-3 align-items-center">
          <div class="col-12 pb-20px">
            <input
              type="Email"
              placeholder="Email"
              class="form-control"
              formControlName="email"
            />
          </div>
          <div class="col-12 position-relative">
            <input
              [type]="hide ? 'password' : 'text'"
              placeholder="Password"
              class="form-control"
              formControlName="password"
            />
            <img
              loading="lazy"
              *ngIf="!hide"
              (click)="hide = !hide"
              src="/assets/img/hide.svg"
              alt=""
              class="visibility-icon"
            />
            <img
              loading="lazy"
              *ngIf="hide"
              (click)="hide = !hide"
              src="/assets/img/show.svg"
              alt=""
              class="visibility-icon"
            />
          </div>
          <div class="forgot-password">
            <a
              href="javascript:void()"
              class="blue-link"
              [routerLink]="['/forgot-password']"
            >
              Forgot password?
            </a>
          </div>
          <div class="col-12">
            <button
              type="submit"
              [disabled]="!signupForm.valid || isLoading"
              class="btn first-btn w-100"
            >
              Login Now >
              <mat-spinner class="float-right" *ngIf="isLoading" diameter="20"> </mat-spinner>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

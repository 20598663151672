import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiService } from '../services/api/api.service';
import { SnackService } from '../services/snack/snack.service';
// import { SocketService } from '../services/socket/socket.service';
import { UserService } from '../services/user/user.service';

@Injectable({
  providedIn: 'root'
})
export class CountryGuard implements CanActivate {

  constructor(private router: Router, private _user: UserService, private _api: ApiService, private _notify: SnackService, @Inject(PLATFORM_ID) private platformId: any
  ) { }

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {

    var canContinue: boolean = true;

    if (isPlatformBrowser(this.platformId)) {
      if (this._user.session && this._user.session.user && this._user.session.user.country == null) {
        this._notify.error("Please set country to continue");
        this.router.navigate(['/profile']);
      } else {
        this._api.me().subscribe(
          (res: any) => {
            if (res.success) {
              this._user.session = res.data;
                // this._socket.initializeSocketConnection(res.data.user);
              if (res.data.user && res.data.user.country == null) {
                this._notify.error("Please set country to continue");
                this.router.navigate(['/profile']);
                this._user.session.country = res.data.user.country;
              }
            }
          },
          (err: any) => {

          });
      }
      // if (!canContinue) this.router.navigate(['/home']);
    }


    return canContinue;


  }

}

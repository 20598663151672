import { moveItemInArray } from '@angular/cdk/drag-drop';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ConfirmationComponent } from 'src/app/components/confirmation/confirmation.component';
import { ApiService } from '../api/api.service';
import { ProgramService } from '../program/program.service';
import { SnackService } from '../snack/snack.service';

@Injectable({
  providedIn: 'root',
})
export class ChapterService {
  public section: any = {};
  public chapter: any = [];
  public isPreview: boolean = false;
  constructor(
    private notify: SnackService,
    private _api: ApiService,
    private _program: ProgramService,
    private _router: Router,
    public dialog: MatDialog
  ) { }

  public createChapter(data, isPreview) {
    return new Promise<void>((resolve, reject) => {
      this._api.createChapter(data).subscribe(
        (res: any) => {
          if (res.success) {
            this._program.program.chapters = res.data.chapters;
            this.chapter = res.data.chapter;
            if (!isPreview) {
              this.notify.success('Chapter created successfully');
            }
            resolve(this.chapter);
          } else {
            this.notify.error(res.message);
            resolve();
          }
        },
        (err: any) => {
          if (err.error) {
            this.notify.error(err.error.message);
            resolve();
          }
        }
      );
    });
  }

  public deleteChapter(id) {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      autoFocus: false,
      panelClass: 'confirmation-panel',
      data: {
        message: 'Are you sure, you want to delete this program?',
        cancel: 'No',
        confirm: 'Yes',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result === true) {
        this._api.deleteChapter(id).subscribe(
          (res: any) => {
            if (res.success) {
              let currentIndex = this._program.program.chapters.findIndex(
                (chapter) => chapter.id === id
              );
              this.selectCurrentChapter(id, res.data, currentIndex);
              this.notify.success('Chapter deleted successfully');
            } else {
              this.notify.error(res.message);
            }
          },
          (err: any) => {
            if (err.error) {
              this.notify.error(err.error.message);
            }
          }
        );
      }
    });
  }

  public duplicateChapter(id) {
    this._api.duplicateChapter(id).subscribe(
      (res: any) => {
        if (res.success) {
          this._program.program.chapters = res.data;
          this.notify.success('Chapter duplicate successfully');
        } else {
          this.notify.error(res.message);
        }
      },
      (err: any) => {
        if (err.error) {
          this.notify.error(err.error.message);
        }
      }
    );
  }

  public createSection(data) {
    this._api.createSection(data).subscribe(
      (res: any) => {
        if (res.success) {
          this.section = res.data;
        } else {
          this.notify.error(res.message);
        }
      },
      (err: any) => {
        if (err.error) {
          this.notify.error(err.error.message);
        }
      }
    );
  }

  public getSections(id) {
    this._api.getSections(id).subscribe(
      (res: any) => {
        if (res.success) {
          this.section = res.data;
          this._program.disk_used = res.data.disk_used;
        } else {
          this.notify.error(res.message);
        }
      },
      (err: any) => {
        if (err.error) {
          this.notify.error(err.error.message);
        }
      }
    );
  }

  public deleteSection(section) {
    this._api.deleteSection(section.id).subscribe(
      (res: any) => {
        if (res.success) {
          this.section = res.data;
          this.setSequence(section.type);
          this.syncSections(this.section, this.chapter.id)
        } else {
          this.notify.error(res.message);
        }
      },
      (err: any) => {
        if (err.error) {
          this.notify.error(err.error.message);
        }
      }
    );
  }

  public uploadMedia(data) {
    return new Promise<void>((resolve, reject) => {
      this._api.uploadMedia(data).subscribe(
        (res: any) => {
          if (res.success) {
            resolve(res.data);
          } else {
            this.notify.error(res.message);
            resolve();
          }
        },
        (err: any) => {
          if (err.error) {
            this.notify.error(err.error.message);
            resolve();
          }
        }
      );
    });
  }

  public uploadVideo(data) {
    return new Promise<void>((resolve, reject) => {
      this._api.uploadVideo(data).then((res: any) => {
        resolve(res);
      });
    });
  }

  public syncSections(data, id) {
    this._api.syncSections(data, id).subscribe(
      (res: any) => {
        if (res.success) {
          this.section = res.data;
        } else {
          this.notify.error(res.message);
        }
      },
      (err: any) => {
        if (err.error) {
          this.notify.error(err.error.message);
        }
      }
    );
  }

  public addSection(chapterType, type, totalChilds = null) {
    this.syncSections(this.section, this.chapter.id);

    let childs: any = [];
    switch (type) {
      case 'attachments':
        childs.push({
          title: null,
          content_type: 'attachment',
          content_value: null,
        });
        break;
      case 'images':
        for (let index = 0; index < totalChilds; index++) {
          childs.push({
            content_type: 'image',
            content_value: null,
          });
        }
        break;
      case 'imagetext':
        childs.push(
          {
            content_type: 'image',
            content_value: null,
          },
          {
            content_type: 'text',
            content_value: null,
          }
        );
        break;
      case 'textimage':
        childs.push(
          {
            content_type: 'text',
            content_value: null,
          },
          {
            content_type: 'image',
            content_value: null,
          }
        );
        break;
      case 'sociallinks':
        childs.push({
          title: null,
          content_type: 'sociallink',
          content_value: null,
        });
        break;
      case 'itemproduct':
        childs.push(
            {
              "content_type": "link",
              "content_value": null
          },
          {
              "content_type": "image",
              "content_value": null
          },
          {
              "content_type": "title",
              "content_value": null
          },
          {
              "content_type": "description",
              "content_value": null
          }
        );
        break;

      default:
        break;
    }

    const section = {
      title: null,
      parent_id: null,
      program_id: this.chapter.program_id,
      chapter_id: this.chapter.id,
      content_type: type,
      content_value: null,
      total_section: null,
      type: chapterType,
      sequence: this.section[chapterType].length,
      childs: childs,
    };

    this.createSection(section);
  }

  public setSequence(chapterType) {
    this.section[chapterType].forEach((v, i = 0) => {
      v.sequence = i;
      i++;
    });
  }

  public removeSection = (section, index, event) => {
    event.stopPropagation();
    this.confirmationDialog(section, index, event);
  };

  public confirmationDialog = (section, index, event) => {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      autoFocus: false,
      panelClass: 'confirmation-panel',
      data: {
        message: 'Are you sure, you want to remove this field?',
        cancel: 'No',
        confirm: 'Yes',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result === true) {
        this.deleteSection(section);
      }
    });
  };

  public sortSeq(type, previousIndex, currentIndex) {
    moveItemInArray(this.section[type], previousIndex, currentIndex);
    this.setSequence(type);
  }

  public sortSequence(type) {
    const temp = [];
    this.section[type].forEach((v) => {
      temp.push(v.sequence);
    });
    temp.sort();
    this.section[type].forEach((v, i = 0) => {
      v.sequence = temp[i];
      i++;
    });
  }

  selectCurrentChapter(id, chapters, index) {
    this._program.program.chapters = chapters;
    let currentIndex = index;
    if (this._program.program.chapters.length > 0 && this.chapter) {
      if (currentIndex >= 0 && this.chapter.id === id) {
        if (currentIndex > 0) {
          currentIndex--;
        }
        this._router.navigateByUrl(
          'training/' +
            this._program.program.slug +
            '/' +
            this._program.program.chapters[currentIndex].slug
        );
      }
    } else {
      this.chapter = [];
      this._router.navigateByUrl(
        'training/' + this._program.program.slug + '/chapter/add'
      );
    }
  }
}

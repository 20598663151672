import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AbstractComponent } from 'src/app/Injector/abstract/abstract.component';

@Component({
  selector: 'app-link-register',
  templateUrl: './link-register.component.html',
  styleUrls: ['./link-register.component.scss']
})
export class LinkRegisterComponent extends AbstractComponent implements OnInit {

  constructor(
    injector: Injector, private activatedRoute: ActivatedRoute) {
    super(injector);
    this.activatedRoute.params.subscribe((params) => {
      localStorage.setItem("invite", params.code);
      this._router.navigate(["/about-us"])
    });
  }

  ngOnInit(): void {
  }

}

import { Component, Injector, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { AbstractComponent } from 'src/app/Injector/abstract/abstract.component';
import { CustomValidators } from 'src/app/providers/CustomValidators';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['../common/common.scss'],
})
export class RegisterComponent extends AbstractComponent implements OnInit {
  public registerForm: FormGroup;
  public isLoading: boolean = false;
  public hide: boolean = true;
  public hideConfirm: boolean = true;
  public isLinkSent: boolean = false;
  public registerFor: any = 'individual';

  constructor(injector: Injector, public _formBuilder: FormBuilder) {
    super(injector);
    this._profile.getCountries();
  }

  ngOnInit(): void {
    this.initRegisterForm();
  }

  public initRegisterForm = () => {
    // this.registerForm = this._formBuilder.group(
    //   {
    //     email: ['', [Validators.required, Validators.email]],
    //     first_name: ['', [Validators.required]],
    //     last_name: ['', [Validators.required]],
    //     password: ['', [Validators.required]],
    //     confirm_password: ['', [Validators.required]],
    //   },
    //   CustomValidators.mustMatch('password', 'confirm_password')
    // );
    this.registerForm = new FormGroup(
      {
        recaptcha_token: new FormControl(''),
        first_name: new FormControl('', [Validators.required]),
        last_name: new FormControl('', [Validators.required]),
        email: new FormControl('', [Validators.required, Validators.email]),
        password: new FormControl('', [Validators.required]),
        confirm_password: new FormControl('', [Validators.required]),
        // country_id: new FormControl('', [Validators.required]),
      },

      CustomValidators.mustMatch('password', 'confirm_password')
    );
  };

  public register(e) {
    if (!this.registerForm.invalid) {
      this.registerForm.patchValue({ recaptcha_token: e });
      this.registerSubmit();
    }
  }

  public requestCaptcha(captcha) {
    if (!this.registerForm.invalid) {
      this.isLoading = true;
      captcha.execute();
    }
  }

  public registerSubmit = () => {
    const data = this.registerForm.getRawValue();
    this._api.userRegister(data).subscribe(
      (res: any) => {
        if (res.success) {
          this.isLoading = false;
          this.isLinkSent = true;
          this._notify.success(res.message);
          
        } else {
          this.isLoading = false;
          this._notify.error(res.message);
        }
      },
      (err: any) => {
        if (err.error) {
          this.isLoading = false;
          this._notify.error(err.error.message);
        }
      }
    );
  };

  get f() {
    return this.registerForm.controls;
  }
}

import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SsoService {

  constructor(@Inject(DOCUMENT) private document: Document) { }


  public googleRedirect() {
    const query = {
      client_id: environment.google_pub_key,
      redirect_uri: environment.baseUrl + "/sso/google",
      response_type: "code",
      scope: "email profile",
    };

    const params = new URLSearchParams();
    for (const key in query) {
      if (query[key]) {
        params.set(key, query[key]);
      }
    }

    this.document.location.href =
      "https://accounts.google.com/o/oauth2/auth/identifier?" +
      params.toString();
  }

  public linkedInRedirect() {

    const query = {
      client_id: environment.linkedin_pub_key,
      redirect_uri: environment.baseUrl + "/sso/linkedin",
      response_type: "code"
    };

    const params = new URLSearchParams();
    for (const key in query) {
      if (query[key]) {
        params.set(key, query[key]);
      }
    }

    this.document.location.href =
      "https://www.linkedin.com/oauth/v2/authorization?" +
      params.toString() + "&scope=r_liteprofile+r_emailaddress";
  }
}

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookiesService } from '../cookies/cookies.service';
import { SnackService } from '../snack/snack.service';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  public avatars: any = [];
  public showChapter: boolean = false;
  public isPublicview: boolean = false;
  public isMobileDevice: boolean = false;

  constructor(
    private _cookies: CookiesService,
    private _router: Router,
    private _notify: SnackService
  ) {}

  public transactionStatus(status) {
    switch (status) {
      case 'paid':
        return;
      case 'received':
        return 'status-received';
      case 'top-up':
        return 'status-topup';
      case 'withdrawal':
        return 'status-withdrawal';
      default:
        break;
    }
  }

  public sum(val1, val2) {
    var val1s = val1;
    var val1num = parseFloat(val1s) || 0;
    var val2s = val2;
    var val2num = parseFloat(val2s) || 0;
    return (val1num) + (val2num);
  }

  public getFileName(file: string) {
    if (file) {
      const m = file.split('/').pop();
      if (m && m.length > 1) {
        return m.split('?')[0];
      }
    }
    return '';
  }

  public redirect() {
    if (localStorage.getItem('redirect')) {
      this._router.navigate([localStorage.getItem('redirect')]);
      localStorage.removeItem('redirect');
    } else if (this._cookies.getCookie('redirect')) {
      const path = this._cookies.getCookie('redirect');
      this._cookies.eraseCookie('redirect');
      this._router.navigateByUrl(path);
    } else {
      this._router.navigate(['/home']);
    }
  }

  public setRedirect(path) {
    this._cookies.setCookie('redirect', path, 1);
  }

  public copyToClipboard() {
    this._notify.success('Link successfully copied in clipboard!');
  }

  public copyPromocode() {
    this._notify.success('Promocode successfully copied in clipboard!');
  }

  public getDevice() {
    try {
      if (typeof window.navigator != undefined && window.navigator.userAgent) {
        if (
          /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            navigator.userAgent
          )
        ) {
          // true for mobile
          this.isMobileDevice = true;
        } else if (/iPad/i.test(navigator.userAgent)) {
          // true for ipad
        } else {
          // true for desktop or laptop
        }
      }
    } catch (error) {
      console.log('Get device error');
    }
  }

  public toggleChapterList = () => {
    this.showChapter = !this.showChapter;
  };

  public hideList = () => {
    this.showChapter = false;
  };
}
